import { RepositoryFactory } from '@/api/repositoryFactory'
import router from '@/js/router'
import auth from '@/js/helpers/auth'
const repository = RepositoryFactory.get('users')

export default {
  state: {
    selectedUserId: null,
    user: {},
    users: {},
    roles: [],
    otherUser: {},
    selectedOtherUserId: null,
    isRegistered: false,
    isUpdated: false,
    isLoggedin: false,
    loadingRegistration: false,
    loadingUpdate: false,
    loadingLogin: false,
    loginError: '',
    accessToken: '',
    loading: false,
    isFollowed: false,
    isBlocked: false,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    userMenuReference: null,
    create: false,
    title: '',
    body: '',
    menu: [
      { slide: 0, name: 'userFlow', label: 'Flow' },
      { slide: 1, name: 'userMedia', label: 'Media' },
      { slide: 2, name: 'userCompany', label: 'Company' }
    ],
    paymentMethod: null,
    confirmedRides: [],
    giveARide: false,
    transactions: [
      {
        uuid: 'e2dc474c-4969-40ee-a564-be4d6ed7f5b1',
        name: 'Marcus Aman',
        amount: 150,
        currency: 'SEK',
        from: 'Söderköping',
        to: 'Norrköping',
        date: '2023-11-27 16:24',
        type: 'card',
        status: 'BOOKED',
        user_id: 2
      },
      {
        uuid: 'e2dc175c-4969-40ee-a564-be4d6ad7f5b2',
        name: 'Marcus Aman',
        amount: 150,
        currency: 'SEK',
        from: 'Söderköping',
        to: 'Norrköping',
        date: '2023-11-26 11:06',
        type: 'card',
        status: 'BOOKED',
        user_id: 2
      },
      {
        uuid: 'e2dc175c-4969-40ee-a564-be4d6jt7f5b3',
        name: 'Marcus Aman',
        amount: 150,
        currency: 'SEK',
        from: 'Söderköping',
        to: 'Norrköping',
        date: '2023-11-25 09:05',
        type: 'card',
        status: 'BOOKED',
        user_id: 2
      },
      {
        uuid: 'e2dc175c-4969-40ee-a564-be4d6uk7f5b4',
        name: 'Marcus Aman',
        amount: 150,
        currency: 'SEK',
        from: 'Söderköping',
        to: 'Norrköping',
        date: '2023-11-24 10:04',
        type: 'card',
        status: 'BOOKED',
        user_id: 2
      }
    ]
  },
  getters: {
    confirmedRides: (state) => state.confirmedRides,
    giveARide: (state) => state.giveARide,
    transactions: (state) => state.transactions,
    paymentMethod: (state) => state.paymentMethod,
    isLoggedin: (state) => state.isLoggedin,
    isRegistered: (state) => state.isRegistered,
    isUpdated: (state) => state.isUpdated,
    loginError: (state) => state.loginError,
    user: (state) => state.user,
    users: (state) => state.users,
    roles: (state) => state.roles,
    companies: (state) => state.user.profile.companies,
    token: (state) => state.accessToken,
    profile: (state) => state.user.profile,
    menu: (state) => state.menu,
    otherUserProfile: (state) => state.otherUser,
    loading: (state) => state.loading,
    isFollowed: (state) => state.isFollowed,
    isBlocked: (state) => state.isBlocked,
    create: (state) => state.create,
    body: (state) => state.body,
    title: (state) => state.title,
    loadingLogin: (state) => state.loadingLogin,
    loadingRegistration: (state) => state.loadingRegistration
  },
  mutations: {
    SET_USER (state, payload) {
      localStorage.user = JSON.stringify(payload)
      state.user = JSON.parse(localStorage.user)
    },
    SET_USER_PROFILE (state, payload) {
      state.user.profile = payload
    },
    IS_REGISTERED (state, boolean) {
      state.isRegistered = boolean
    },
    IS_UPDATED (state, boolean) {
      state.isUpdated = boolean
    },
    IS_LOGGED_IN (state, boolean) {
      state.isLoggedin = boolean
    },
    LOADING_REGISTRATION (state, boolean) {
      state.loadingRegistration = boolean
    },
    LOADING_UPDATE (state, boolean) {
      state.loadingUpdate = boolean
    },
    LOADING_LOGIN (state, boolean) {
      state.loadingLogin = boolean
    },
    SET_TOKEN (state, token) {
      state.accessToken = token
      auth.storeToken(token)
    },
    SET_TEMPORARY_TOKEN (state, token) {
      state.accessToken = token
      auth.storeTemporaryToken(token)
    },
    SET_LOGIN_ERROR (state, error) {
      state.loginError = error
    },
    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    SET_LOADING_NEW (state, boolean) {
      state.loadingNew = boolean
    },
    REMOVE_USER (state, userId) {
      delete state.users[userId]
      state.users = { ...state.users }
    },
    SET_LIST (state, payload) {
      payload.map((user) => {
        state.users = { ...state.users, [user.id]: user }
      })

      state.users = { ...state.users }
    },
    SET_ROLES (state, payload) {
      state.roles = [...payload]
    },
    SELECT_OTHER_USER (state, otherUserId) {
      state.selectedOtherUserId = otherUserId
    },
    IS_FOLLOWED (state, boolean) {
      state.isFollowed = boolean
    },
    IS_BLOCKED (state, boolean) {
      state.isBlocked = boolean
    },
    SET_ALERT (state, payload) {
      state.title = payload.title
      state.body = payload.body
      state.create = payload.create
    },
    REMOVE_TOKEN (state, payload) {
      state.accessToken = ''
      state.user = {}
      auth.clearToken()
    },
    SET_PROFILE_IMAGE (state, payload) {
      state.user.profile.image = payload
      localStorage.user = JSON.stringify(state.user)
    }
  },
  actions: {
    addRide ({ state }, payload) {
      state.confirmedRides = []
      if (payload) {
        state.confirmedRides = [payload]
      }
    },
    selectPaymentMethod ({ state }, payload) {
      state.paymentMethod = payload
    },
    async one ({ commit, state }) {
      commit('SET_LOADING', true)
      const response = await repository.one(state.selectedOtherUserId)
      commit('SET_LOADING', false)
      if (response.status === 200 && response.data) {
        commit('SET_LIST', response.data)
      } else {
        console.log('No response found')
      }
    },
    async profile ({ commit }) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.profile()
        commit('SET_LOADING', false)
        if (response.status === 200) {
          commit('SET_USER_PROFILE', response.data)
        } else {
          console.log('No response found')
        }
      } catch (e) {
        commit('SET_LOADING', false)
        console.log('Error occured : ' + e)
      }
    },
    selectUser ({ commit, dispatch, state, rootState }, userId) {
      commit('SELECT_OTHER_USER', userId)
    },
    async fetch ({ state, commit, dispatch }) {
      if (auth.getAccessToken()) {
        commit('SET_TOKEN', auth.getAccessToken())
        commit('IS_LOGGED_IN', true)
      }

      if (localStorage.user !== undefined && localStorage.user !== '') {
        commit('SET_USER', JSON.parse(localStorage.user))
      }

      if (state.accessToken !== '' && state.accessToken !== null && state.accessToken !== undefined) {
        try {
          const response = await repository.profile()
          commit('SET_USER', response.data)
        } catch (e) {
          console.log(e)
        }
      }
    },
    async fetchRoles ({ state, commit, dispatch }) {
      try {
        const response = await repository.roles()
        commit('SET_ROLES', response.data)
      } catch (e) {
        console.log(e)
      }
    },
    setAccessToken ({ commit }, token) {
      commit('SET_TOKEN', token)
    },
    async login ({ commit, dispatch }, payload) {
      try {
        commit('LOADING_LOGIN', true)
        const response = await repository.login(payload)
        commit('LOADING_LOGIN', false)
        if (response.status === 200 || response.status === 201) {
          if (response.data.token !== undefined && response.data.token.length > 0) {
            commit('IS_LOGGED_IN', true)
            commit('SET_TOKEN', response.data.token)
            commit('SET_USER', response.data.user)
            router.push({ name: 'ride' })
          }
        }
      } catch (e) {
        commit('LOADING_LOGIN', false)
        commit('SET_ALERT', {
          create: true,
          title: 'Login Error!',
          body: 'Invalid credentials. please check your Email Id or Password'
        })
        commit('SET_LOGIN_ERROR', 'Wrong email or password!')
      }
    },
    async register ({ commit, state, dispatch, rootState }, payload) {
      commit('LOADING_REGISTRATION', true)
      payload.timezone = state.timezone
      try {
        const response = await repository.register(payload)
        commit('LOADING_REGISTRATION', false)
        if (response.status === 200) {
          commit('IS_REGISTERED', true)
          commit('IS_LOGGED_IN', true)
          commit('SET_TOKEN', response.data.token)
          commit('SET_USER', response.data.user)
          router.push({ name: 'dashboard' })
          setTimeout(() => {
            commit('IS_REGISTERED', false)
          }, 3000)
        } else {
          commit('ERROR_REGISTERED', true)
          setTimeout(() => {
            commit('ERROR_REGISTERED', false)
          }, 5000)
        }
      } catch (e) {
        commit('LOADING_REGISTRATION', false)
        commit('SET_ALERT', { create: true, title: 'Signup Error!', body: 'Please insert valid information' })
        commit('ERROR_REGISTERED', true)
        setTimeout(() => {
          commit('ERROR_REGISTERED', false)
        }, 5000)
        console.log(e)
      }
    },
    async resetError ({ commit }) {
      commit('SET_LOGIN_ERROR', '')
    },
    async update ({ commit, state, dispatch }, payload) {
      try {
        payload.timezone = state.timezone
        payload.name = state.user.profile.name
        commit('LOADING_UPDATE', true)
        const response = await repository.update(payload)
        commit('LOADING_UPDATE', false)
        if (response.status === 200) {
          dispatch('fetch')
        }
      } catch (e) {
        console.log(e)
        commit('LOADING_UPDATE', false)
        commit('SET_LOGIN_ERROR', 'Error when trying to save!')
      }
    },
    async profileImage ({ commit, state }, payload) {
      try {
        commit('SET_PROFILE_IMAGE', payload.profile)
        const response = await repository.profileImage(payload)
        if (response.status === 204) {
          console.log('Image uploaded successfully')
        }
      } catch (e) {
        console.log('Error occured :' + e)
      }
    },
    async logout ({ commit, state }) {
      try {
        commit('REMOVE_TOKEN')
        commit('IS_LOGGED_IN', false)
        router.push({ name: 'login' })
      } catch (error) {
        console.log(error)
      }
    },
    async guest ({ commit }) {
      return new Promise(async (resolve, reject) => {
        if (!auth.getAccessToken()) {
          try {
            const response = await repository.guest()
            if (response.status === 200 && response.data.token !== undefined && response.data.token.length > 0) {
              commit('SET_TEMPORARY_TOKEN', response.data.token)
              resolve()
            }
          } catch (e) {
            reject(e)
          }
        } else {
          resolve()
        }
      })
    },
    async fetchAll ({ commit }) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.all()
        commit('SET_LOADING', false)
        if (response.status === 200 && response.data) {
          commit('SET_LIST', response.data)
        }
      } catch (e) {
        commit('SET_LOADING', false)
      }
    },
    async add ({ state, commit }, payload) {
      console.log(payload)
      commit('SET_LOADING_NEW', true)
      let response
      if (payload.id) {
        response = await repository.update(payload.id, payload)
        commit('SET_LIST', [response.data])
      } else {
        response = await repository.add(payload)
        commit('SET_LIST', [response.data.user])
      }
      commit('SET_LOADING_NEW', false)
    },
    async destroy ({ state, commit }, user) {
      commit('SET_LOADING_NEW', true)
      await repository.remove(user.id)
      commit('REMOVE_USER', user.id)
      commit('SET_LOADING_NEW', false)
    },
    giveARide ({ state }, boolean) {
      state.giveARide = boolean

      state.transactions.push({
        uuid: 'e2dc474c-4969-40ee-a564-be4d6ed7f5b1',
        name: 'Christoffer Johansson',
        amount: 150,
        currency: 'SEK',
        from: 'Göteborg',
        to: 'Norrköping',
        date: '2023-11-27 16:24',
        type: 'card',
        status: 'BOOKED',
        user_id: 1
      })
    }
  },
  namespaced: true
}
