<template>
  <div></div>
</template>

<script>

export default {
  name: 'Alert',
  props: {
    title: {
      type: String,
      default: ''
    },
    warning: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    },
    danger: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.alertTitle = this.title

    if (this.success) {
      this.alertType = 'success'
    } else if (this.warning) {
      this.alertType = 'warning'
    } else if (this.danger) {
      this.alertType = 'danger'
    } else {
      this.alertType = 'info'
    }

    this.message = this.$slots.default[0].text
  },
  data () {
    return {
      width: '350',
      alertType: 'info',
      alertTitle: '',
      message: ''
    }
  },
  methods: {
    showAlert () {
      const self = this
      this.VaModal.alert({
        title: this.alertTitle,
        message: this.message,
        type: this.alertType,
        width: this.width,
        onShow () {
          self.$emit('show')
        },
        onHide () {
          self.$emit('hide')
        },
        onConfirm () {
          self.$emit('confirm')
        }
      })
    }
  }
}
</script>
