<template>
  <div>
    <AlertModal
      :create="create"
      :title="title"
      :body="body"
      :show-confirm-button="showConfirmButton"
      @toggle="$emit('toggle')"
      @agree="$emit('agree')"
    />
  </div>
</template>

<script>

import AlertModal from './_components/AlertModal'

export default {
  name: 'AlertModalModule',
  components: {
    AlertModal
  },
  props: {
    create: { default: false },
    title: { default: '' },
    body: { default: '' },
    showConfirmButton: { default: false }
  }
}
</script>
