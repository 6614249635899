import usersRepository from './real/users'
import companyAreasRepository from './real/companyAreas'
import questionsRepository from './real/questions'
import whistleblowersRepository from './real/whistleblowers'
import companiesRepository from './real/companies'
import ridesRepository from './real/rides'

const repositories = {
  users: usersRepository,
  companyAreas: companyAreasRepository,
  questions: questionsRepository,
  whistleblowers: whistleblowersRepository,
  companies: companiesRepository,
  rides: ridesRepository
}

export const RepositoryFactory = {
  get: (name) => repositories[name]
}

export default RepositoryFactory
