<template>
  <button @click="$emit('click', $event)" class="bg-white border px-2 border-black hover:underscore text-black rounded-md focus:outline-none">
    <slot></slot>
  </button>
</template>

<script>

export default {
  name: 'SquareButton',
  props: {
    secondary: { default: false }
  },
  computed: {
    isSecondary () {
      return (this.secondary !== false)
    }
  }
}
</script>
