<template>
  <div>
    <RideContainer />
    <MapTopGradient />
    <MapBackgroundContainer height-class="h-screen" />
  </div>
</template>

<script>
import RideContainer from '@/modules/ride'
import MapBackgroundContainer from '@/modules/mapBackground'

export default {
  name: 'Ride',
  components: {
    RideContainer,
    MapBackgroundContainer
  }
}
</script>
