<template>
  <div class="flex flex-col items-center h-screen bg-primary-medium">
    <Container padding="p-0">
      <Icon
        class="absolute top-2 text-white cursor-pointer text-2xl"
        name="arrow-back"
        @click="$router.go(-1)"
      />
      <Logo icon-class="w-12" />
      <div class="font-semibold flex flex-col w-full">
        <RouterLink
          :to="{ name: 'ride' }"
          class="border-b border-primary-light text-white px-3 py-4"
        >
          Search Ride
        </RouterLink>
        <RouterLink
          :to="{ name: 'bookings' }"
          class="border-b border-primary-light text-white px-3 py-4"
        >
          Bookings
        </RouterLink>
        <RouterLink
          :to="{ name: 'events' }"
          class="border-b border-primary-light text-white px-3 py-4"
        >
          Events
        </RouterLink>
        <RouterLink
          :to="{ name: 'profile' }"
          class="border-b border-primary-light text-white px-3 py-4"
        >
          My Profile
        </RouterLink>
        <RouterLink
          :to="{ name: 'myRides' }"
          class="border-b border-primary-light text-white px-3 py-4"
        >
          My Rides
        </RouterLink>
        <RouterLink
          :to="{ name: 'payments' }"
          class="border-b border-primary-light text-white px-3 py-4"
        >
          Payments
        </RouterLink>
        <RouterLink
          :to="{ name: 'profile' }"
          class="border-b border-primary-light text-white px-3 py-4"
        >
          Settings
        </RouterLink>
        <RouterLink
          to="/"
          class="border-b border-primary-light text-white px-3 py-4"
        >
          Log out
        </RouterLink>
      </div>
    </Container>
  </div>
</template>
<script>
export default {
  name: 'Menu'
}
</script>
