<template>
  <div class="w-full h-full flex justify-center">
    <div class="m-auto w-10/12">
      <!-- <Logo icon-class="w-40" /> -->
      <form @submit.prevent="$emit('login')">
        <div class="">
          <div
            class="flex h-14 mb-2 items-center bg-white rounded-default text-gray-400 border-b border-solid border-gray-400 "
          >
            <span class="px-3">
              <Icon
              class="text-primary-dark"
                name="envelope"
                solid
              />
            </span>
            <BaseInput
              placeholder="Email"
              value="christoffer"
              required
              @input="$emit('email', $event)"
            />
          </div>
          <div class="flex h-14 mb-2 items-center bg-white rounded-default text-gray-400">
            <span class="px-3">
              <Icon
              class="text-primary-dark"
                name="lock-alt"
                solid
              />
            </span>
            <BaseInput
              type="password"
              placeholder="Password"
              value="password"
              required
              @input="$emit('password', $event)"
            />
          </div>
          <p class="decoration-stone-200 text-white text-xs font-bold my-2 text-right">
            FORGOT PASSWORD ?
          </p>
          <RouterLink :to="{name: 'payment-method'}">
            <base-button
              extra-class="h-52p uppercase mt-3"
              text="Log in"
            />
          </RouterLink>
        </div>
      </form>
      <!-- <RouterLink :to="{name: 'signup'}"> -->
        <p class="decoration-stone-200 text-white text-center text-base font-bold mt-8">
          REGISTER ACCOUNT
        </p>
      <!-- </RouterLink> -->
    </div>
  </div>
</template>
<script>
import Icon from '../../../components/Icon.vue'

export default {
  name: 'Login',
  props: {
    email: { default: '' },
    password: { default: '' }
  },
  components: { Icon }
}

</script>
