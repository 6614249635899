<template>
  <div class="flex items-center w-full  relative">
    <img v-if="src!==''" :src="src" class="rounded-full" :class="size" />
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'AvatarSlot',
  props: {
    src: { default: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png' },
    size: { default: 'w-10 h-10 mr-2' }
  }
}
</script>
