<template>
  <ul>
    <li class="hidden">
      <slot></slot>
    </li>
    <li
      class="flex items-center text-white"
      v-for="(text, index) in list"
      :key="'text'+index"
    >
      <FontAwesomeIcon
        class="mr-2 text-xl text-primary-light"
        icon="check-circle"
      /><span>{{text}}</span>
    </li>
  </ul>
</template>

<script>

export default {
  name: 'CheckList',
  data () {
    return {
      list: []
    }
  },
  mounted () {
    let list = []
    this.$slots.default.map(node => {
      const text = node.elm.innerText || node.elm.textContent
      list.push(text)
    })

    this.list = list
  }
}
</script>

<style lang="scss" scoped>
ul {
  li {
    list-style: none;
    @apply p-0;
    @apply m-0;
    @apply py-2;

    &::before {
      display: none;
      font-family: "Font Awesome 5 Solid";
      content: "\f245";
    }
  }
}
</style>
