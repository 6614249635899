<template>
  <div
    class="border-t-[7px] border-t-primary-medium"
  >
    <Container>
      <div v-if="id">
        <div class="flex items-center mb-8">
          <RouterLink
            :to="{name: 'menu'}"
            class="flex items-center"
          >
            <Icon
              name="menu"
              class="text-3xl mr-2 h-6 mt-1 text-primary-medium"
            />
          </RouterLink>
          <h2 class="text-xl font-semibold">
            <span class="block">Booking</span>
            <span class="text-xs block">{{ id }}</span>
          </h2>
        </div>
        <RouterLink
            :to="{name: 'bookings'}"
            class="flex items-center mb-4 font-semibold"
          >
          <Icon
            name="arrow-back"
            class="text-xl mr-2"
            />
            <span>Back to Bookings</span>
        </RouterLink>
        <div class="flex items-center justify-between">
          <div class="flex flex-col w-full">
            <div class="py-3 border-b border-gray-200"><span class="inline-block w-20">Name</span>{{selectedTransaction.name}}</div>
            <div class="py-3 border-b border-gray-200"><span class="inline-block w-20">Type</span>{{selectedTransaction.type}}</div>
            <div class="py-3 border-b border-gray-200"><span class="inline-block w-20">Date</span>{{selectedTransaction.date}}</div>
            <div class="py-3 border-b border-gray-200"><span class="inline-block w-20">From</span>{{selectedTransaction.from}}</div>
            <div class="py-3 border-b border-gray-200"><span class="inline-block w-20">To</span>{{selectedTransaction.to}}</div>
            <div class="py-3 border-b border-gray-200"><span class="inline-block w-20">Amount</span>{{selectedTransaction.amount}} {{selectedTransaction.currency}}</div>
            <div class="py-3 border-b border-gray-200"><span class="inline-block w-20">Status</span>{{selectedTransaction.status}}</div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="flex items-center">
          <RouterLink
            :to="{name: 'menu'}"
            class="flex items-center"
          >
            <Icon
              name="menu"
              class="text-3xl mr-2 h-6 mt-1 text-primary-medium"
            />
          </RouterLink>
          <h2 class="text-xl font-semibold">
            Bookings
          </h2>
        </div>
        <div  class="my-8">
          <h2 class="text-xl font-semibold mb-4">
            You as driver
          </h2>
          <div v-if="yourRides.length > 0">
            <div v-for="transaction in yourRides"
                :key="transaction.uuid"
                class="mb-4 block" :to="{name: 'bookings-single', params: {id: transaction.uuid}}">
                <GrayCard>
                  <div class="flex items-center justify-between">
                    <div class="flex items-center">
                      <Icon
                        name="map"
                        class="text-2xl mr-4"
                        solid
                      />
                      <div>
                        <div class="text-lg font-medium">
                          {{transaction.from}} to {{transaction.to}}
                        </div>
                        <div>
                            {{transaction.date}}
                        </div>
                      </div>
                    </div>
                    <Icon
                      name="chevron-right"
                      class="text-3xl mr-2 h-6 mt-1 text-primary"
                    />
                  </div>
                </GrayCard>
            </div>
          </div>
          <div v-else>
            You have not planned a ride yet
          </div>
        </div>
        <div>
          <h2 class="text-xl font-semibold">
            Ride along with others
          </h2>
        </div>
        <div class="mt-8">
          <RouterLink
            v-for="transaction in otherRides"
            :key="transaction.uuid"
          class="mb-4 block" :to="{name: 'bookings-single', params: {id: transaction.uuid}}">
            <GrayCard>
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <Icon
                  name="map"
                  class="text-2xl mr-4"
                  solid
                />
                <div>
                  <div class="text-lg font-medium">
                    {{transaction.from}} to {{transaction.to}}
                  </div>
                  <div>
                    with
                      <b>{{transaction.name}}</b>
                  </div>
                  <div>
                      {{transaction.date}}
                  </div>
                </div>
              </div>
              <Icon
                name="chevron-right"
                class="text-3xl mr-2 h-6 mt-1 text-primary"
              />
            </div>
          </GrayCard>
          </RouterLink>
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Bookings',
  computed: {
    ...mapGetters({
      transactions: 'users/transactions'
    }),
    selectedTransaction () {
      return this.transactions.find(t => t.uuid === this.id)
    },
    yourRides () {
      return this.transactions.filter(t => t.user_id === 1)
    },
    otherRides () {
      return this.transactions.filter(t => t.user_id !== 1)
    }
  },
  data () {
    return {
      id: null
    }
  },
  mounted () {
    this.id = this.$route.params.id
  }
}
</script>
