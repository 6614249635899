<template>
  <div
    class="bg-primary-medium h-full"
  >
    <Container class="h-full">
      <div class="flex flex-col items-center">
        <div>

          <Avatar
          src="../../../img/profile.png"
          classes="w-12 h-12"
          name="Christoffer Johansson"
          />

        </div>
        <h1 class="text-xl py-8 font-semibold text-white">
          Welcome Christoffer!
          </h1>
        <h2 class="text-xl font-semibold text-white text-center">
          Please begin with choosing a payment method
        </h2>
      </div>
      <div class="mt-8 h-full border-t border-primary-medium-dark">
        <GreenCard class="py-6 cursor-pointer" @click.native="choosePaymentMethod('swish')">
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <Icon
                name="wallet"
                class="text-2xl mr-4 text-white"
              />
              <div>
                <div class="text-lg font-medium text-white">
                  Swish
                </div>
              </div>
            </div>
            <Icon
              name="chevron-right"
              class="text-3xl mr-2 h-6 mt-1 text-primary  text-white"
            />
          </div>
        </GreenCard>
        <GreenCard class="py-6 cursor-pointer" @click.native="choosePaymentMethod('card')">
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <Icon
                name="credit-card"
                class="text-2xl mr-4  text-white"
                solid
              />
              <div>
                <div class="text-lg font-medium text-white">
                  Card Payment
                </div>
              </div>
            </div>
            <Icon
              name="chevron-right"
              class="text-3xl mr-2 h-6 mt-1 text-primary  text-white"
            />
          </div>
        </GreenCard>
        <GreenCard class="py-6 cursor-pointer" @click.native="choosePaymentMethod('apple')">
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <Icon
                name="wallet-alt"
                class="text-2xl mr-4  text-white"
                solid
              />
              <div>
                <div class="text-lg font-medium text-white">
                  Apple pay
                </div>
              </div>
            </div>
            <Icon
              name="chevron-right"
              class="text-3xl mr-2 h-6 mt-1 text-primary  text-white"
            />
          </div>
        </GreenCard>
      </div>
    </Container>
  </div>
</template>

<script>
export default {
  name: 'PaymentMethod',
  components: {
  },
  methods: {
    choosePaymentMethod (method) {
      this.$store.dispatch('users/selectPaymentMethod', method)
      this.$router.push({ name: 'ride' })
    }
  }
}
</script>
