<template>
  <div
    class="border-t-[7px] border-t-primary-medium"
  >
    <div v-if="id">
      <div class="items-center place-content-center">
        <div class="absolute inset-0 mt-[7px] h-32">
          <BannerImage class="h-32 w-full" :src="selectedEvent.background" />
        </div>
        <div class="h-32 mb-12 relative w-full">

          <Container padding="p-0">
            <RouterLink
              :to="{name: 'menu'}"
              class="absolute top-2 bg-primary-medium"
            >
              <Icon
                name="menu"
                class="text-3xl text-white px-2"
              />
            </RouterLink>
            <Logo icon-class="w-12" />
            <div class="flex items-center place-content-center">
              <RouterLink :to="{ name: 'profile' }">
                <Avatar
                  classes="w-24 h-24"
                  :src="selectedEvent.profile"
                  name="Benjamin"
                />
              </RouterLink>
            </div>
          </Container>
        </div>

        <div class="bg-white flex flex-col items-center">

            <RouterLink
                :to="{name: 'events'}"
                class="flex items-center mb-4 font-semibold cursor-pointer"
              >
              <Icon
                name="arrow-back"
                class="text-xl mr-2"
              />
              <span>Back to Events</span>
            </RouterLink>
            <Container>
              <div class="flex items-center justify-between w-full">
                <div class="flex flex-col w-full">
                  <div class="py-3 border-b border-gray-200 text-xl font-bold text-center">{{selectedEvent.name}}</div>
                  <GrayCard v-for="location in selectedEvent.locations" :key="location.uuid" class="mb-6 w-full">
                    <div class="py-3 border-b border-gray-200"><span class="inline-block w-20">Location</span>{{location.name}}</div>
                    <div class="py-3 border-b border-gray-200"><span class="inline-block w-20">To</span>{{location.to}}</div>
                    <div class="py-3 border-b border-gray-200"><span class="inline-block w-20">Date</span>{{location.date}}</div>
                  </GrayCard>
                </div>
              </div>
            </Container>
          </div>
        </div>
    </div>
    <Container v-else>

      <div>
        <div class="flex items-center">
          <RouterLink
            :to="{name: 'menu'}"
            class="flex items-center"
          >
            <Icon
              name="menu"
              class="text-3xl mr-2 h-6 mt-1 text-primary-medium"
            />
          </RouterLink>
          <h2 class="text-xl font-semibold">
            Events &amp; Happenings
          </h2>
        </div>
        <div class="mt-8">
          <RouterLink
            v-for="event in events"
            :key="event.uuid"
          class="mb-4 block" :to="{name: 'events-single', params: {id: event.uuid}}">
            <GrayCard>
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <Icon
                  name="map"
                  class="text-2xl mr-4"
                  solid
                />
                <div>
                  <div>
                      <b>{{event.name}}</b>
                  </div>
                  <div class="text-lg font-medium">
                    {{event.locations.length}} events
                  </div>
                </div>
              </div>
              <Icon
                name="chevron-right"
                class="text-3xl mr-2 h-6 mt-1 text-primary"
              />
            </div>
          </GrayCard>
          </RouterLink>
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Events',
  computed: {
    ...mapGetters({
      events: 'events/events'
    }),
    selectedEvent () {
      return this.events.find(e => e.uuid === this.id)
    }
  },
  data () {
    return {
      id: null
    }
  },
  mounted () {
    this.id = this.$route.params.id
  }
}
</script>
