<template>
  <button class="inline-block font-medium leading-6 focus:outline-none"  :class="design">Like</button>
</template>

<script>

export default {
  name: 'LikeButton',
  props: {
    design: { default: 'w-20 text-left' }
  }
}
</script>
