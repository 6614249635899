<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default DatePicker
</script>

<style lang="scss">
.mx-datepicker {
  @apply w-full;
}

.mx-input {
  @apply border-none;
  @apply shadow-none;
  @apply text-lg;
  @apply pl-8;
  // @apply bg-gray-200;
  // @apply text-base;
  // @apply text-black;
  // @apply rounded-full;
}

.mx-calendar-content .cell.active {
  // @apply bg-primary;
}
.mx-calendar-content .cell.in-range {
  // @apply bg-primary;
}
</style>
