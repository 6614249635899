<template>
  <div class="z-20 relative">
    <div class="w-full h-16 fixed top-0 z-1 bg-white border-t-[7px] border-primary-medium"></div>
    <div class="w-full h-12 fixed top-16 z-1 bg-gradient-to-b from-white"></div>
  </div>
</template>

<script>
export default {
  name: 'MapTopGradient'
}
</script>
