<template>
  <va-dropdown>
    <div slot="trigger">
      <va-button ref="type" icon-after="angle-down">
        {{type}}
      </va-button>
    </div>
    <li class="p-2 border-b hover:bg-gray-100 cursor-pointer" v-for="option in options" :key="option.value" @click="$emit('click', {element: $refs.type.$el, option})">
      <va-icon size="0.8rem" color="#fff" :bg-color="option.color" padding="8px" :type="option.icon" /> {{option.label}}
    </li>
  </va-dropdown>
</template>

<script>

export default {
  name: 'Types',
  props: {
    type: {
      default: ''
    },
    options: {
      default: () => []
    }
  }
}
</script>
