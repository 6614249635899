<template>
  <Confirm />
</template>
<script>
import Confirm from './_components/Confirm'
export default {
  name: 'ConfirmModule',
  components: {
    Confirm
  }
}
</script>
