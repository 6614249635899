<template>
  <div class="logout flex justify-center p-8">
    <va-loading size="xl" color="blue"/>
  </div>
</template>

<script>

export default {
  name: 'Logout',
  created () {
    this.$store.dispatch('users/logout')
  }
}
</script>
