<template>
  <div class="mt-4 h-full">
    <div class="flex justify-center relative h-full">
      <SearchRideContainer>
        <div class="flex h-14 items-center bg-white rounded-default text-gray-750 border-b border-solid border-gray-400">
          <input type="text" placeholder="Package height" class="w-full h-full outline-none px-8 text-lg" />
        </div>
        <div class="flex h-14 items-center bg-white rounded-default text-gray-750 border-b border-solid border-gray-400">
          <input type="text" placeholder="Package width" class="w-full h-full outline-none px-8 text-lg" />
        </div>
        <div class="flex h-14 items-center bg-white rounded-default text-gray-750 border-b border-solid border-gray-400">
          <input type="text" placeholder="Package weight" class="w-full h-full outline-none px-8 text-lg" />
        </div>
      </SearchRideContainer>
    </div>
  </div>
</template>
<script>
import SearchRideContainer from '@/modules/searchRide/'

export default {
  name: 'SendPackage',
  components: {
    SearchRideContainer
  },
  props: {}
}
</script>
