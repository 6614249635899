import { get, put, post } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}/questions`

const all = async () => get(`${url}`)
const byUser = async () => get(`${url}/user`)
const one = async (id) => get(`${url}/${id}`)
const rate = async (data) => post(`${url}/rate`, data)
const add = async (data) => post(`${url}`, data)
const update = async (questionId, data) => put(`${url}/${questionId}`, data)
const activate = async (questionId) => put(`${url}/${questionId}/activate`)

export default {
  all,
  byUser,
  rate,
  add,
  one,
  activate,
  update
}
