<template>
  <div
    class="border-t-[7px] border-t-primary-medium"
  >
    <Container>
      <div v-if="id">
        <div class="flex items-center mb-8">
          <RouterLink
            :to="{name: 'menu'}"
            class="flex items-center"
          >
            <Icon
              name="menu"
              class="text-3xl mr-2 h-6 mt-1 text-primary-medium"
            />
          </RouterLink>
          <h2 class="text-xl font-semibold">
            <span class="block">Payment</span>
            <span class="text-xs block">{{ id }}</span>
          </h2>
        </div>
        <RouterLink
            :to="{name: 'payments'}"
            class="flex items-center mb-4 font-semibold"
          >
          <Icon
            name="arrow-back"
            class="text-xl mr-2"
            />
            <span>Back to Payments</span>
        </RouterLink>
        <div class="flex items-center justify-between">
          <div class="flex flex-col w-full">
            <div class="py-3 border-b border-gray-200"><span class="inline-block w-20">Name</span>{{selectedTransaction.name}}</div>
            <div class="py-3 border-b border-gray-200"><span class="inline-block w-20">Type</span>{{selectedTransaction.type}}</div>
            <div class="py-3 border-b border-gray-200"><span class="inline-block w-20">Date</span>{{selectedTransaction.date}}</div>
            <div class="py-3 border-b border-gray-200"><span class="inline-block w-20">From</span>{{selectedTransaction.from}}</div>
            <div class="py-3 border-b border-gray-200"><span class="inline-block w-20">To</span>{{selectedTransaction.to}}</div>
            <div class="py-3 border-b border-gray-200"><span class="inline-block w-20">Amount</span>{{selectedTransaction.amount}} {{selectedTransaction.currency}}</div>
            <div class="py-3 border-b border-gray-200"><span class="inline-block w-20">Status</span>{{selectedTransaction.status}}</div>
          </div>
        </div>
        <button class="bg-gray-750 my-20 w-full text-gray-800 font-bold p-2 flex justify-center mt-4">
          <div class="flex items-center">
            <icon
              name="receipt"
              class="text-2xl text-white mr-16 ml-2"
              solid
            />
            <span class="text-white mr-24">Download Receipt</span>
          </div>
        </button>
      </div>
      <div v-else>
        <div class="flex items-center">
          <RouterLink
            :to="{name: 'menu'}"
            class="flex items-center"
          >
            <Icon
              name="menu"
              class="text-3xl mr-2 h-6 mt-1 text-primary-medium"
            />
          </RouterLink>
          <h2 class="text-xl font-semibold">
            Payments
          </h2>
        </div>
        <div class="mt-8">
          <RouterLink
            v-for="transaction in transactions"
            :key="transaction.uuid"
          class="mb-4 block" :to="{name: 'payments-single', params: {id: transaction.uuid}}">
            <GrayCard>
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <Icon
                  name="credit-card"
                  class="text-2xl mr-4"
                  solid
                />
                <div>
                  <div class="text-lg font-medium">
                    {{transaction.from}} to {{transaction.to}}
                  </div>
                  <div>
                    with
                      <b>{{transaction.name}}</b>
                  </div>
                  <div>{{transaction.amount}} {{transaction.currency}}</div>
                </div>
              </div>
              <Icon
                name="chevron-right"
                class="text-3xl mr-2 h-6 mt-1 text-primary"
              />
            </div>
          </GrayCard>
          </RouterLink>
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
export default {
  name: 'Payments',
  computed: {
    selectedTransaction () {
      return this.transactions.find(t => t.uuid === this.id)
    }
  },
  data () {
    return {
      id: null,
      transactions: [
        {
          uuid: 'e2dc474c-4969-40ee-a564-be4d6ed7f5b1',
          name: 'Marcus Aman',
          amount: 150,
          currency: 'SEK',
          from: 'Söderköping',
          to: 'Norrköping',
          date: '2023-11-27',
          type: 'card',
          status: 'PAID'
        },
        {
          uuid: 'e2dc175c-4969-40ee-a564-be4d6ad7f5b2',
          name: 'Marcus Aman',
          amount: 150,
          currency: 'SEK',
          from: 'Söderköping',
          to: 'Norrköping',
          date: '2023-11-26',
          type: 'card',
          status: 'PAID'
        },
        {
          uuid: 'e2dc175c-4969-40ee-a564-be4d6jt7f5b3',
          name: 'Marcus Aman',
          amount: 150,
          currency: 'SEK',
          from: 'Söderköping',
          to: 'Norrköping',
          date: '2023-11-25',
          type: 'card',
          status: 'PAID'
        },
        {
          uuid: 'e2dc175c-4969-40ee-a564-be4d6uk7f5b4',
          name: 'Marcus Aman',
          amount: 150,
          currency: 'SEK',
          from: 'Söderköping',
          to: 'Norrköping',
          date: '2023-11-24',
          type: 'card',
          status: 'PAID'
        }
      ]
    }
  },
  mounted () {
    this.id = this.$route.params.id
  }
}
</script>
