<template>
  <div class="bg-red-100 p-4 rounded-lg my-4 text-red-700 border border-red-700 flex items-center">
    <FontAwesomeIcon icon="exclamation-circle" class="text-2xl mr-4" /> <div><slot></slot></div>
  </div>
</template>

<script>

export default {
  name: 'ErrorMessage'
}
</script>
