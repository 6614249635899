import { get, put, post } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}/whistleblowers`

const all = async () => get(`${url}`)
const add = async (data) => post(`${url}`, data)
const update = async (whistleId, data) => put(`${url}/${whistleId}`, data)
const setStatus = async (whistleId, data) => put(`${url}/${whistleId}/setStatus`, data)

export default {
  all,
  add,
  setStatus,
  update
}
