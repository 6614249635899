<template>
  <div class="relative flex w-full flex-wrap items-stretch">
   <input :type="type" :placeholder="placeholder" :value="value" @input="$emit('input', $event)" class="w-full py-2 px-6 text-gray-800 rounded-md rounded-3xl border bg-white border-gray-800 focus:outline-none" />
   <span class="z-10 h-full leading-snug font-normal absolute text-center text-gray-800 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
    <FontAwesomeIcon icon="link"/>
  </span>
</div>
</template>

<script>

export default {
  name: 'LinkInput',
  props: {
    type: { default: 'text' },
    value: { default: '' },
    placeholder: { default: 'link' }
  }
}
</script>
