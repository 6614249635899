<template>
  <BaseButton @click="$emit('click', $event)" :class="isSecondary ? 'border border-blue-500 hover:border-blue-700 text-blue-500 rounded-full hover:text-blue-700' : 'bg-white border border-black hover:underscore text-black rounded-full'">
    <slot></slot>
  </BaseButton>
</template>

<script>

export default {
  name: 'NeutralButton',
  props: {
    secondary: { default: false }
  },
  computed: {
    isSecondary () {
      return (this.secondary !== false)
    }
  }
}
</script>
