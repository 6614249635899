<template>
  <div class="w-full h-screen flex items-center">
    <AlertModal :create="create" @toggle="toggle" :title="title" :body="body"/>
    <div class="flex justify-center p-8" v-if="loadingRegistration"><Loader /></div>
    <Signup v-else
      :email="email"
      :firstName="firstName"
      :familyName="familyName"
      :name="name"
      :org-no="orgNo"
      :phone="phone"
      :password="password"
      @register="register"
      @email="email = $event"
      @password="password = $event"
      @org-no="orgNo = $event"
      @phone="phone = $event"
      @name="name = $event"
      @first-name="firstName = $event"
      @family-name="familyName = $event"
    />
  </div>
</template>

<script>

import Signup from './_components/Signup'
import { mapGetters } from 'vuex'
import router from '@/js/router'
import AlertModal from '@/modules/alertModal'

export default {
  name: 'SignupModule',
  components: {
    Signup,
    AlertModal
  },
  computed: {
    ...mapGetters({
      isLoggedin: 'users/isLoggedin',
      isRegistered: 'users/isRegistered',
      user: 'users/user',
      userProfile: 'users/userProfile',
      loadingRegistration: 'users/loadingRegistration',
      create: 'users/create',
      title: 'users/title',
      body: 'users/body'
    })
  },
  data () {
    return {
      firstName: '',
      familyName: '',
      name: '',
      orgNo: '',
      phone: '',
      password: '',
      email: ''
    }
  },
  methods: {
    register () {
      this.$store.dispatch('users/register', {
        first_name: this.firstName,
        family_name: this.familyName,
        name: this.name,
        org_no: this.orgNo,
        phone: this.phone,
        password: this.password,
        email: this.email,
        type: 'local'
      })
    },
    toggle () {
      this.$store.commit('users/SET_ALERT', { create: false })
    }
  },
  beforeCreate () {
    if (localStorage.getItem('accessToken')) {
      router.push({ name: 'dashboard' })
    }
  }
}
</script>
