<template>
  <!-- <input :type="type" :value="value" @keyup.enter="$emit('submit')" @input="$emit('input', $event)" class="px-4 py-2 rounded-md border bg-gray-200 border-gray-800 focus:outline-none" /> -->
  <div :id="'map-box-' + name"></div>
</template>
<script>
import mapboxgl from 'mapbox-gl'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'

export default {
  name: 'MapBoxSearch',
  props: {
    placeholder: { type: String, default: '' },
    name: { type: String, default: '' }
  },
  data () {
    return {
      geocoder: null
    }
  },
  computed: {
    localGeocoder () {
      let features = []

      this.$store.getters['events/events'].map(event => {
        event.locations.map(location => {
          features.push(
            {
              type: 'Feature',
              properties: {
                display: event.name + ' - ' + location.name + '<br>' + location.to + ' - ' + location.date,
                title: event.name + ' - ' + location.name + ' - ' + location.to + ' - ' + location.date,
                to: location.to
              },
              geometry: location.geometry
            }
          )
        })
      })

      return { features }
    }
  },
  watch: {
    localGeocoder (oldValue, newValue) {
      this.loadGeocoder()
    }
  },
  mounted () {
    this.loadGeocoder()
  },
  methods: {
    loadGeocoder () {
      const self = this

      this.geocoder = new MapboxGeocoder({
        accessToken: process.env.VUE_APP_MAPBOX_KEY,
        mapboxgl: mapboxgl,
        localGeocoder: this.forwardGeocoder,
        marker: false,
        placeholder: this.placeholder,
        minLength: 3
      })

      this.geocoder.addTo('#map-box-' + this.name)

      this.geocoder.on('result', (e) => {
        console.log('RESULT OMS', e)
        self.$emit('get-result', e)
      })
    },
    forwardGeocoder (query) {
      const matchingFeatures = []
      for (const feature of this.localGeocoder.features) {
        // Handle queries with different capitalization
        // than the source data by calling toLowerCase().
        if (
          feature.properties.title
            .toLowerCase()
            .includes(query.toLowerCase()) ||
             feature.properties.to
               .toLowerCase()
               .includes(query.toLowerCase())
        ) {
          // Add a tree emoji as a prefix for custom
          // data results using carmen geojson format:
          // https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
          feature['place_name'] = `📅 ${feature.properties.display}`
          feature['center'] = feature.geometry.coordinates
          feature['place_type'] = ['point']
          matchingFeatures.push(feature)
        }
      }
      return matchingFeatures
    }
  }
}
</script>
<style>
.mapboxgl-ctrl-geocoder.mapboxgl-ctrl,
.mapboxgl-ctrl-geocoder--input {
  max-height: inherit;
  height: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  border: none;
  width: 100%;
  z-index: auto;
  box-shadow: none;
  max-width: 100%;
}
.mapboxgl-ctrl-geocoder--icon-search {
  top: 18px;
}
.mapboxgl-ctrl-geocoder--icon-close {
  margin-top: 12px;
}
.mapboxgl-ctrl-geocoder--input:focus {
  outline: none;
}
</style>
