<template>
  <div class="items-center place-content-center relative">
    <div class="bg-primary-medium h-32">
      <Container padding="p-0">
        <RouterLink
          :to="{name: 'menu'}"
          class="absolute top-2"
        >
          <Icon
            name="menu"
            class="text-3xl mr-2 text-white"
          />
        </RouterLink>
        <Logo icon-class="w-12" />
        <div class="flex justify-center aspect-square">
          <Avatar
            src="../../../img/profile.png"
            name="Bonnie Green"
            minified
          />
        </div>
      </Container>
    </div>

    <div class="bg-white h-full w-full flex flex-col items-center">
      <Container padding="p-0">
        <div class="w-full flex flex-col items-center">
          <h5 class="mt-16 text-xl item-center font-medium text-gray-900 dark:text-white">
            Bonnie Green
          </h5>
          <div class="flex items-center">
            <Icon
              name="star"
              class="text-primary-medium"
              solid
            />
            <Icon
              name="star"
              class="text-primary-medium"
              solid
            />
            <Icon
              name="star"
              class="text-primary-medium"
              solid
            />
            <Icon
              name="star"
              class="text-primary-medium"
              solid
            />
            <Icon
              name="star"
              class="text-gray-star"
              solid
            />
            <small class="text-10p mt-1 ml-1 font-bold">57 votes</small>
          </div>
          <div class="w-full">
            <button
              id="menu-button"
              type="button"
              class="flex items-center w-full font-bold bg-white py-2 text-lg font-medium"
              aria-expanded="true"
              aria-haspopup="true"
            >
              Ratings
              <Icon
                name="down-arrow"
                class="text-10p ml-2 mt-1"
                solid
              />
            </button>
          </div>
          <div class="w-full">
            <carousel
              :per-page="1"
              :mouse-drag="true"
              :center-mode="false"
              :adjustable-height="true"
              pagination-color="#d1d5db"
              pagination-active-color="#2fac68"
              :pagination-size="10"
            >
              <slide>
                <ProfileCard background="#f3f4f6" />
              </slide>
              <slide>
                <ProfileCard background="#f3f4f6" />
              </slide>
              <slide>
                <ProfileCard background="#f3f4f6" />
              </slide>
              <slide>
                <ProfileCard background="#f3f4f6" />
              </slide>
              <slide>
                <ProfileCard background="#f3f4f6" />
              </slide>
              <slide>
                <ProfileCard background="#f3f4f6" />
              </slide>
            </carousel>
          </div>
          <!-- <div class="flex space-x-1 mt-3">
        <small class="relative bg-primary-medium rounded-full w-2 h-2"></small>
        <small class="relative bg-gray-300 rounded-full w-2 h-2"></small>
        <small class="relative bg-gray-300 rounded-full w-2 h-2"></small>
        <small class="relative bg-gray-300 rounded-full w-2 h-2"></small>
        <small class="relative bg-gray-300 rounded-full w-2 h-2"></small>
        <small class="relative bg-gray-300 rounded-full w-2 h-2"></small>
        <small class="relative bg-gray-300 rounded-full w-2 h-2"></small>
      </div> -->
          <div class="w-full">
            <p
              class="mt-10 w-full relative font-bold bg-white py-2 text-lg font-medium text-left"
              aria-expanded="true"
              aria-haspopup="true"
            >
              Driver Data
            </p>
          </div>
          <div class="bg-gray-100 w-full">
            <div class="p-1 px-3">
              <small class="text-10p font-semibold text-gray-400 leading-none">Volvo v60| Diesel </small>
              <p class="text-sm font-bold leading-none">
                Standard
              </p>
            </div>
            <hr>
            <div class="p-1 px-3">
              <small class="text-10p font-semibold text-gray-400 leading-none">Volvo v60| Diesel </small>
              <p class="text-sm font-bold leading-none">
                Standard
              </p>
            </div>
            <hr>
            <div class="p-1 px-3">
              <small class="text-10p font-semibold text-gray-400 leading-none">Volvo v60| Diesel </small>
              <p class="text-sm font-bold leading-none">
                Standard
              </p>
            </div>
            <hr>
            <div class="p-3 px-3 item-center place-content-center">
              <div class="flex items-center place-content-center">
                <p class="text-sm font-bold leading-none">
                  More
                </p>
                <Icon
                  name="down-arrow"
                  class="text-10p"
                  solid
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  </div>
</template>
<script>
import ProfileCard from '../../../components/ProfileCard.vue'
import { Carousel, Slide } from 'vue-carousel'
export default {
  name: 'OtherUserProfile',
  components: {
    Carousel,
    Slide,
    ProfileCard
  }
}
</script>
