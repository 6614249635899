<template>
  <Ride
    :deliver-a-package="deliverAPackage"
    :give-a-ride="giveARide"
    :user="user"
    @toggle-deliver-a-package="deliverAPackage = !deliverAPackage"
    @toggle-give-a-ride="giveARide = !giveARide"
  />
</template>
<script>
import Ride from './_components/Ride'
import { mapGetters } from 'vuex'

export default {
  name: 'RideModule',
  components: {
    Ride
  },
  data () {
    return {
      deliverAPackage: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'users/user',
      giveARide: 'users/giveARide'
    })
  },
  methods: {
    cross () {
    },
    setShowSearchList () {
      this.showList = !this.showList
    }
  }
}
</script>
