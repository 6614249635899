<template>
  <BaseButton @click="$emit('click', $event)" :class="isSecondary ? 'border border-primary hover:border-gray-800 text-primary hover:text-gray-800' : 'bg-primary hover:bg-gray-800 text-white'">
    <slot></slot>
  </BaseButton>
</template>

<script>

export default {
  name: 'PositiveButton',
  props: {
    secondary: { default: false }
  },
  computed: {
    isSecondary () {
      return (this.secondary !== false)
    }
  }
}
</script>
