<template>
  <BaseButton
    :noPadding="noPadding"
    @click="$emit('click', $event)"
    :class="isSecondary ? 'border border-primary hover:border-primary text-primary hover:text-primary' : 'bg-primary hover:bg-primary text-white'"
  >
    <slot></slot>
  </BaseButton>
</template>

<script>

export default {
  name: 'AlertButton',
  props: {
    secondary: { default: false },
    noPadding: { default: false }
  },
  computed: {
    isSecondary () {
      return (this.secondary !== false)
    }
  }
}
</script>
