<template>
  <ConfirmContainer />
</template>

<script>
import ConfirmContainer from '@/modules/confirm'

export default {
  name: 'Confirm',
  components: {
    ConfirmContainer
  }
}
</script>
