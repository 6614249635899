<template>
  <!-- <button @click="$emit('click', $event)" :class="hasNoPadding ? '' : 'py-2 px-4'" class="font-bold text-shadow rounded cursor-pointer">
    <slot></slot>
  </button> -->
  <va-button @click="$emit('click', $event)" :class="'bg-primary-dark text-white text-lg font-bold w-full p-0 py-2 ' + extraClass">
    <div class="flex items-center"><span class="pl-2"><slot>{{text}}</slot></span></div>
  </va-button>
</template>

<script>

export default {
  name: 'BaseButton',
  props: {
    extraClass: { default: '' },
    text: { default: '' }
  },
  computed: {
    // hasNoPadding() {
    //   return (this.noPadding !== false)
    // }
  }
}
</script>

<style>
  .va-btn-default:hover:not(.va-btn-disabled) {
    background-color: theme('colors.primary.dark');
    color: theme('colors.white');;
  }
  </style>
