
import Vue from 'vue'
import VueI18n from 'vue-i18n'

var requireLanguageFile = require.context('./languages/', true, /\.json$/)

let allLanguages = []

requireLanguageFile.keys().forEach(fileName => {
  const moduleConfig = requireLanguageFile(fileName)
  const file = fileName.substring(fileName.lastIndexOf('/') + 1)
  const moduleName = file.replace(/\.\w+$/, '')

  allLanguages[moduleName] = moduleConfig
})

Vue.use(VueI18n)

export const browserLanguage = (navigator.language || navigator.userLanguage).split('-')[0]
export const defaultLocale = 'en'
export const specificLanguage = (localStorage.language !== undefined && localStorage.language !== '') ? localStorage.language : browserLanguage

export const languages = allLanguages

export const messages = Object.assign(languages)

export const i18n = new VueI18n({
  locale: specificLanguage,
  fallbackLocale: defaultLocale,
  messages
})
