<template>
  <LoginContainer />
</template>

<script>

import LoginContainer from '@/modules/login'

export default {
  name: 'Login',
  components: {
    LoginContainer
  }
}
</script>
