<template>
  <BaseCard class="rounded-none z-10 shadow-none">
      <template #body>
          <AvatarSlot :src="src">
              <BaseInput :value="message" :disabled="disable" @submit="$emit('submit')" @input="$emit('message', $event)" class="flex-shrink flex-grow rounded-full text-xs inline-flex" :placeholder="placeholder"></BaseInput>
              <slot></slot>
          </AvatarSlot>
      </template>
  </BaseCard>
</template>

<script>

export default {
  name: 'AvatarInput',
  props: {
    src: { default: '' },
    message: { default: '' },
    placeholder: { default: 'Write Something here.. ' },
    disable: { default: false }
  }
}
</script>
