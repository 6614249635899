<template>
    <div class="w-full  px-1">
        <hr class="hr-text mt-6">
    </div>
</template>

<script>
export default {
  name: 'Divider'
}
</script>

<style  scoped>

.hr-text {
  line-height: 1em;
  position: relative;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
}
.hr-text:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;
    padding: 0 .5em;
    color: #818078;
    background-color: #EFEFEF;
  }
.hr-text:before {
    content: '';
    background: black;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
</style>
