<template>
  <div class="bg-gray-200 h-screen bg-map flex flex-col items-center border-gray-400 border-t-[7px] border-t-primary-medium">
    <Container class="h-full">
      <!-- <hr class="h-2.5 bg-primary-medium absolute top-0 w-10/12" /> -->
      <div class="flex items-center">
        <a @click="$router.go(-1)">
          <Icon
            class="font-bold text-base cursor-pointer"
            name="bx bx-arrow-back"
            solid
          />
        </a>
        <div class="flex items-center justify-center">
          <p class="text-xs font-bold text-base text-gray-750 ml-4">
            Back
          </p>
        </div>
      </div>
      <div class="h-full bg-gray-200 w-full flex items-center justify-center">
        <div class="items-center justify-center w-full">
          <div class="flex flex-row items-center justify-center space-x-3">
            <icon
              name="wallet"
              class="text-2xl text-gray-400 justify-center"
              solid
            />
            <div class="items-center justify-center py-20">
              <small class="text-10p font-bold text-gray-400 leading-none">Your current credits </small>
              <p class="text-lg font-bold text-base text-gray-750 leading-none">
                1 578 SEK
              </p>
            </div>
          </div>
          <div class="rounded shadow-lg bg-white flex justify-center">
            <div class="px-6 py-4 h-26 space-y-0">
              <p class="text-xs font-bold leading-none">
                You are going with Marcus from
              </p>
              <p class="text-xs font-bold text-base text-gray-750 leading-none">
                Stockholm to Norrköping
              </p>
              <small class="text-xs font-bold text-primary-medium leading-none">Thursday 10:00 </small>
              <div class="flex space-x-1">
                <p class="text-lg font-bold ml-48 mt-1 leading-none">
                  150 kr
                </p>
                <Icon
                  class="font-bold text-base transform rotate-180"
                  name="bx bx-arrow-back"
                  solid
                />
              </div>
            </div>
          </div>
          <RouterLink :to="{ name: 'confirm' }">
            <button class="bg-primary-medium w-full mt-1 text-gray-800 font-bold py-2 px-4 inline-flex items-center justify-center">
              <div class="flex flex-row items-center justify-center space-x-3 relative w-full">
                <Icon
                  name="check-circle"
                  class="text-2xl text-white absolute left-0"
                  solid
                />
                <span class="text-white text-base">Confirm seat</span>
              </div>
            </button>
          </RouterLink>
          <div class="h-12 mt-1 text-gray-800 font-bold py-2 px-4 flex justify-center items-center">
            <span class="text-gray-400 cursor-pointer">
              <a @click="$router.go(-1)"> Cancel booking </a>
            </span>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
export default {
  name: 'CurrentCredit'
}
</script>
