<template>
  <va-tooltip
  trigger="hover"
  :content="name"
  placement="bottom"
  effect="tooltip-fade-bottom">
    <div v-if="letter !== ''" class="w-6 h-6 rounded-full text-white font-bold flex text-xs justify-center items-center" :style="{background: color}">{{letter}}</div>
    <img v-else-if="src !== ''" :src="src" class="rounded-full" :class="classes" />
  </va-tooltip>
</template>

<script>

export default {
  name: 'Avatar',
  props: {
    src: {
      default: ''
    },
    classes: {
      default: ''
    },
    letter: {
      default: ''
    },
    color: {
      default: '#ccc'
    },
    name: {
      default: ''
    },
    minified: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
  .va-form-control {
    max-height: inherit;
    height: inherit;
    font-size: inherit;
    color: inherit;
    border: none;
  }
  </style>
