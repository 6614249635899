<template>
  <Types
    :type="type"
    :options="options"
    @click="click"
    v-if="!icon"
  />
  <TypeIcon
    :icon="selectedType.icon"
    :color="selectedType.color"
    :label="selectedType.label"
    :small="small"
  v-else />
</template>

<script>

import Types from './_components/Types'
import TypeIcon from './_components/TypeIcon'

export default {
  name: 'TypesModule',
  components: {
    Types,
    TypeIcon
  },
  props: {
    icon: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    selected: {
      default: ''
    }
  },
  computed: {
    selectedType () {
      return this.options.find(option => option.value === this.selected)
    }
  },
  data () {
    return {
      type: 'feature',
      options: [
        {
          value: 'feature',
          label: 'Feature',
          icon: 'th-list',
          color: '#57c5a2'
        },
        {
          value: 'bug',
          label: 'Bug',
          icon: 'bug',
          color: '#e45757'
        },
        {
          value: 'hotfix',
          label: 'Hotfix',
          icon: 'fire',
          color: '#6cabc5'
        }
      ]
    }
  },
  methods: {
    click (payload) {
      this.type = payload.option.value
      this.$emit('type', this.type)
      payload.element.click()
    }
  }
}
</script>
