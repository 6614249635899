<template>
  <BaseCard
    padding="p-2"
    :justify="justify"
    :items="items"
    background="bg-gray-200"
    class="border-b-2 borde-gray-300"
  >
    <template #body>
      <slot />
    </template>
  </BaseCard>
</template>

<script>

export default {
  name: 'GrayCard',
  props: {
    padding: { type: String, default: 'p-4' },
    justify: { type: String, default: 'start' },
    items: { type: String, default: 'start' },
    background: { type: String, default: 'bg-white' }
  },
  methods: {
    slotExist (type) {
      return this.$slots[type] !== undefined && this.$slots[type].length > 0
    }
  }
}
</script>
