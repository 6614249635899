<template>
  <button @click="$emit('click',$event)" class="float-right bg-grey-light text-grey-darkest rounded inline-flex focus:outline-none"><FontAwesomeIcon icon="ellipsis-h" /></button>
</template>

<script>

export default {
  name: 'DotMenu'
}
</script>
