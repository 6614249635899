<template>
  <Profile
    :is-edit="isEdit"
    :email="email"
    :name="name"
    :user="user"
    @click-edit="editToggle()"
  />
</template>
<script>
import Profile from './_components/Profile'
import { mapGetters } from 'vuex'

export default {
  name: 'ProfileModule',
  components: {
    Profile
  },
  data () {
    return {
      isEdit: false,
      email: this.user?.email || '',
      name: this.user?.name || ''
    }
  },
  computed: {
    ...mapGetters({
      user: 'users/user'
    })
  },
  async mounted () {
    if (this.user) {
      this.email = this.user?.email
      this.name = this.user?.name
    }
  },
  methods: {
    editToggle () {
      this.isEdit = !this.isEdit
    }
  }
}
</script>
