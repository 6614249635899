<template>
  <div class="flex">
    <Icon name="star" class="text-primary-medium" solid />
    <Icon name="star" class="text-primary-medium" solid />
    <Icon name="star" class="text-primary-medium" solid />
    <Icon name="star" class="text-primary-medium" solid />
    <Icon name="star" class="text-gray-star" solid />
  </div>
</template>

<script>
export default {
  name: 'Rating',
  props: {
    rating: { type: Number, default: 0 }
  }
}
</script>
