<template>
  <div class="bg-white p-4 rounded-lg my-4 text-gray-600 border border-primary flex items-center">
    <FontAwesomeIcon
      icon="info-circle"
      class="text-2xl mr-4 text-primary"
    />
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'InfoMessage'
}
</script>
