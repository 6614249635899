<template>
  <div class="w-full h-full bg-primary-medium flex justify-center">
    <div class="max-w-xs m-auto">
    <div class="">
      <Logo />
      <form @submit.prevent="$emit('login')">
        <div class="mx-auto">
          <div
            class="flex h-14 items-center bg-white rounded-default text-gray-400 border-b border-solid border-gray-400 ">
            <span class="px-3">
              <Icon name="lock-alt" solid />
            </span>
            <base-input placeholder="Full Name" :value="fullName" @input="$emit('fullName', $event)" required>
            </base-input>
          </div>
          <div
            class="flex h-14 items-center bg-white rounded-default border-b border-solid border-gray-400 text-gray-400">
            <span class="px-3">
              <icon name="envelope" solid />
            </span>
            <base-input placeholder="Email" :value="email" @input="$emit('email', $event)" required></base-input>
          </div>
          <div
            class="flex h-14 items-center bg-white rounded-default text-gray-400 border-b border-solid border-gray-400 ">
            <span class="px-3">
              <Icon name="lock-alt" solid />
            </span>
            <base-input type="password" placeholder="Password" @input="$emit('password', $event)" :value="password"
              required></base-input>
          </div>
          <RouterLink :to="{name: 'ride'}">
            <base-button extraClass="h-52p uppercase my-3.5" text="REGISTER NOW"></base-button>
          </RouterLink>
        </div>
      </form>
      <RouterLink :to="{name: 'login'}">
        <p class="decoration-stone-200 text-white text-center text-base font-bold mt-2.5">LOGIN INSTEAD</p>
      </RouterLink>
    </div>
  </div>
  </div>
</template>

<script>

export default {
  name: 'Signup',
  components: {
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    firstName: { type: String, default: '' },
    familyName: { type: String, default: '' },
    orgNo: { type: String, default: '' },
    phone: { type: String, default: '' },
    password: { type: String, default: '' },
    email: { type: String, default: '' }
  }
}
</script>
