<template>
  <AvatarSlot :src="src">
    <div class="block flex flex-1 justify-between">
      <div class="w-11/12 flex flex-col">
        <div class="text-xs font-bold" v-if="link"><RouterLink :to="link">{{name}}</RouterLink><slot></slot></div>
        <div class="text-xs font-bold" v-else>{{name}}<slot></slot></div>
        <p class="text-gray-600 text-xs">{{subtitle}}</p>
      </div>
    </div>
  </AvatarSlot>
</template>

<script>

export default {
  name: 'AvatarNameSubTitle',
  props: {
    src: { default: '' },
    name: { default: '' },
    link: { default: null },
    subtitle: { default: '' }
  }
}
</script>
