<template>
  <div
    class="mx-auto w-11/12 sm:w-8/12 md:w-6/12 lg:w-5/12 px-4 sm:px-8"
    :class="padding"
  >
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'Container',
  props: {
    padding: { default: 'py-6' }
  }
}
</script>
