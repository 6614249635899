export const rides = [
  {
    id: 1,
    name: 'Marcus Aman',
    img: '../../../../img/profile.png',
    vehicle: 'Volvo v60| Diesel ',
    amount: 150,
    rating: 4,
    votes: 50
  },
  {
    id: 2,
    name: 'Marcus Johansson',
    img: '../../../../img/profile.png',
    vehicle: 'Volvo v60| Bensin ',
    amount: 150,
    rating: 4,
    votes: 30
  }
]
