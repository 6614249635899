<template>
  <div
    class="border-t-[7px] border-t-primary-medium"
  >
    <Container>
      <div class="flex items-center">
        <RouterLink
          :to="{name: 'menu'}"
          class="flex items-center"
        >
          <Icon
            name="menu"
            class="text-3xl mr-2 h-6 mt-1 text-primary-medium"
          />
        </RouterLink>
        <h2 class="text-xl font-semibold">
          MyRides
        </h2>
      </div>
      <div class="mt-8">
        <GrayCard>
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <Icon
                name="car"
                class="text-xl mr-2"
                solid
              />
              <div>Volvo XC 60</div>
            </div>
            <Icon
              name="edit-alt"
              class="text-3xl mr-2 h-6 mt-1 text-primary"
              solid
            />
          </div>
        </GrayCard>
      </div>
    </Container>
  </div>
</template>

<script>
export default {
  name: 'MyRides',
  components: {
  }
}
</script>
