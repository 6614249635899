<template>
  <CurrentCreditContainer />
</template>

<script>
import CurrentCreditContainer from '@/modules/currentCredit'

export default {
  name: 'CurrentCredit',
  components: {
    CurrentCreditContainer
  }
}
</script>
