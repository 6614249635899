<template>
  <div class="bg-purple-100 p-4 rounded-lg my-4 text-purple-600 border border-purple-500 flex items-center">
    <FontAwesomeIcon icon="info-circle" class="text-2xl mr-4 text-purple-500" /> <div><slot></slot></div>
  </div>
</template>

<script>

export default {
  name: 'InfoMessage'
}
</script>
