<template>
  <div>
    <SendPackageContainer />
    <MapTopGradient />
    <MapBackgroundContainer height-class="h-screen" />
  </div>
</template>

<script>
import SendPackageContainer from '@/modules/sendPackage'
import MapBackgroundContainer from '@/modules/mapBackground'

export default {
  name: 'SendPackage',
  components: {
    SendPackageContainer,
    MapBackgroundContainer
  }
}
</script>
