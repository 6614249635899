<template>
  <textarea :value="value" @input="$emit('input', $event)" class="px-6 py-2 rounded-md resize border bg-gray-200 border-gray-800 focus:outline-none" ></textarea>
</template>

<script>

export default {
  name: 'BaseTextarea',
  props: {
    value: { default: '' }
  }
}
</script>
