<template>
  <div id="map" :class="heightClass + ' mapboxgl-map z-10 relative'"></div>
</template>
<script>
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'

import mapboxgl from 'mapbox-gl'
import { mapGetters } from 'vuex'

export default {
  name: 'MapBackground',
  props: {
    heightClass: {
      type: String,
      default: 'h-screen'
    }
  },
  computed: {
    ...mapGetters({
      from: 'searcRideForm/from',
      to: 'searcRideForm/to',
      time: 'searcRideForm/time',
      showList: 'searcRideForm/showList',
      selectedRide: 'searcRideForm/selectedRide'
    })
  },
  watch: {
    from: {
      deep: true,
      handler (from) {
        console.log('Oh, look, a new color!', from)
        this.setFromMarker()
        this.setNavigation()
      }
    },
    to: {
      deep: true,
      handler (to) {
        console.log(this.to)
        this.setToMarker()
        this.setNavigation()
      }
    }
  },
  data: () => {
    return {
      center: [0, 0],
      map: {},
      access_token: process.env.VUE_APP_MAPBOX_KEY
    }
  },
  mounted () {
    this.createMap()
  },
  methods: {
    async createMap () {
      mapboxgl.accessToken = this.access_token
      this.map = new mapboxgl.Map({
        container: 'map', // container ID
        // style: 'mapbox://styles/negirpesh28/ckyrb71eldm4e14pptsf4q9v4', // style URL
        style: 'mapbox://styles/mapbox/light-v9', // style URL
        center: [-74.5, 40], // starting position [lng, lat]
        zoom: 10 // starting zoom
      })
    },
    setFromMarker () {
      new mapboxgl.Marker().setLngLat(this.from.center).addTo(this.map)
      this.map.setCenter(this.from.center)
    },
    setToMarker () {
      new mapboxgl.Marker().setLngLat(this.to.center).addTo(this.map)
      this.map.setCenter(this.to.center)
    },
    setNavigation () {
      console.log(this.from.center, this.to.center)
      if (this.from.center && this.to.center) {
        this.getRoute()
      }
    },
    async getRoute (end) {
      // make a directions request using cycling profile
      // an arbitrary start will always be the same
      // only the end or destination will change
      const query = await fetch(
        `https://api.mapbox.com/directions/v5/mapbox/driving/${this.from.center[0]},${this.from.center[1]};${this.to.center[0]},${this.to.center[1]}?steps=true&geometries=geojson&access_token=${this.access_token}`,
        { method: 'GET' }
      )
      const json = await query.json()
      const data = json.routes[0]
      const route = data.geometry.coordinates
      const geojson = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: route
        }
      }
      // if the route already exists on the map, we'll reset it using setData
      if (this.map.getSource('route')) {
        this.map.getSource('route').setData(geojson)
      } else {
        this.map.addLayer({
          id: 'route',
          type: 'line',
          source: {
            type: 'geojson',
            data: geojson
          },
          layout: {
            'line-join': 'round',
            'line-cap': 'round'
          },
          paint: {
            'line-color': '#3887be',
            'line-width': 5,
            'line-opacity': 0.75
          }
        })
      }
      // add turn instructions here at the end
    }
  }
}
</script>
<style>
.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right,
.mapboxgl-ctrl-top-left,
.mapboxgl-ctrl-top-right {
  position: unset;
}
</style>
