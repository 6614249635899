import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('companyAreas')

export default {
  state: {
    companyAreas: {},
    selectedProjectId: null,
    loading: false,
    loadingNew: false
  },
  getters: {
    loading: state => state.loading,
    loadingNew: state => state.loadingNew,
    companyAreas: state => Object.values(state.companyAreas)
  },
  mutations: {
    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    SET_LOADING_NEW (state, boolean) {
      state.loadingNew = boolean
    },
    REMOVE_COMPANY (state, companyAreaId) {
      delete state.companyAreas[companyAreaId]
      state.companyAreas = { ...state.companyAreas }
    },
    SET_LIST (state, payload) {
      payload.map(companyArea => {
        state.companyAreas = { ...state.companyAreas, [companyArea.id]: companyArea }
      })

      state.companyAreas = { ...state.companyAreas }
    }
  },
  actions: {
    async fetchAll ({ commit }) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.all()
        commit('SET_LOADING', false)
        if ((response.status === 200) && response.data) {
          commit('SET_LIST', response.data)
        }
      } catch (e) {
        commit('SET_LOADING', false)
      }
    },
    async add ({ state, commit }, payload) {
      commit('SET_LOADING_NEW', true)
      commit('SET_LOADING', true)
      let response
      if (payload.id) {
        response = await repository.update(payload.id, payload)
      } else {
        response = await repository.add(payload)
      }
      commit('SET_LIST', [response.data])
      commit('SET_LOADING_NEW', false)
      commit('SET_LOADING', false)
    },
    async destroy ({ state, commit }, companyArea) {
      commit('SET_LOADING_NEW', true)
      await repository.remove(companyArea.id)
      commit('REMOVE_COMPANY', companyArea.id)
      commit('SET_LOADING_NEW', false)
    }
  },
  namespaced: true
}
