<template>
  <div class="w-full relative">
    <button
      class="bg-primary-medium w-full text-gray-800 font-bold p-2"
      @click="getResult()"
    >
      <span class="text-white text-base">Search</span>
    </button>
    <div
      v-if="showList"
      class="flex flex-col items-center border w-full bg-gray-200 border-gray-400 relative bottom-0 mt-2"
    >
      <RideCard
        v-for="ride in rideList"
        :key="ride.id"
        :ride="ride"
        @click="selectRide(ride.id)"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SearchRideList',
  props: {},
  computed: {
    ...mapGetters({
      from: 'searcRideForm/from',
      to: 'searcRideForm/to',
      rideList: 'searcRideForm/rideList',
      showList: 'searcRideForm/showList'
    })
  },
  mounted () {
    this.$store.dispatch('searcRideForm/setShowList', false)
  },
  methods: {
    getResult () {
      console.log(this.from, this.to)
      if (this.from.center && this.to.center) {
        this.$store.dispatch('searcRideForm/getRideResult')
      } else {
        this.$store.dispatch('searcRideForm/setShowList', false)
      }
    },
    selectRide (id) {
      this.$store.dispatch('searcRideForm/setSelectedRide', this.rideList?.find(ride => ride.id === id))
    }
  }
}
</script>
