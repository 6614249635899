<template>
  <BaseButton @click="$emit('click', $event)" :class="isSecondary ? 'border border-red-500 hover:border-red-700 text-red-500 hover:text-red-700' : 'bg-red-500 hover:bg-red-700 text-white'">
    <slot></slot>
  </BaseButton>
</template>

<script>

export default {
  name: 'NegativeButton',
  props: {
    secondary: { default: false }
  },
  computed: {
    isSecondary () {
      return (this.secondary !== false)
    }
  }
}
</script>
