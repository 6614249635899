<template>
  <div class="flex justify-between text-xs pt-5 w-full">
    <!-- <RouterLink :to="{ name: 'ride' }"> -->
    <div
      class="flex mt-3"
    >
      <a @click="$router.go(-1)">
        <Icon
          id="result"
          class="mt-1.5 font-bold text-base cursor-pointer"
          name="bx bx-arrow-back"
          solid
        />
      </a>
      <p class="text-xs font-bold text-base text-gray-750 mt-1 ml-2">
        Give a ride
      </p>
    </div>
    <div class="w-10">
      <Avatar
        src="/img/profile.png"
        classes="rounded-full dark:bg-gray-500 aspect-square mt-3 w-10"
        name="Christopher"
      />
      <p
        class="text-xs font-bold flex items-center text-primary-medium mt-2.5 float-right"
      >
        Settings
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'GiveRideHeader',
  props: {
    selectedRide: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
