import { get, put, post } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}/rides`

const all = async () => get(`${url}`)
const one = async (id) => get(`${url}/${id}`)
const add = async (data) => post(`${url}`, data)
const update = async (companyId, data) => put(`${url}/${companyId}`, data)
const remove = async (companyId) => get(`${url}/${companyId}/delete`)

export default {
  all,
  add,
  one,
  remove,
  update
}
