<template>
  <div
    id="app"
    class="h-full"
  >
    <div class="flex flex-1 h-full">
      <div :class="['w-full h-full']">
        <router-view :key="$route.fullPath" />
      </div>
    </div>
    <PortalTarget
      name="dialog"
      multiple
    />
  </div>
</template>

<script>
// import auth from '@/js/helpers/auth'
import { mapGetters } from 'vuex'
import store from '@/js/store'
import 'boxicons/css/boxicons.min.css'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import 'animate.css'

export default {
  name: 'App',
  computed: {
    ...mapGetters({
      isLoggedin: 'users/isLoggedin'
    })
  },
  async beforeCreate () {
    await store.dispatch('users/guest')
    this.$store.dispatch('languages/init')
  },
  data () {
    return {
      paymentMethod: null
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('users/logout')
    }
  }
}
</script>
