<template>
  <div class="z-20 w-full">
    <div class="flex items-center bg-white rounded-default border-b border-solid border-gray-400 relative z-20">
      <MapBoxSearch
        class="h-14 w-full text-base text-gray-750 font-bold"
        :name="'from'"
        placeholder="From"
        @get-result="getResult($event, 'from')"
      />
    </div>
    <div
      class="flex items-center bg-white rounded-default text-gray-400 border-b border-solid border-gray-400 relative z-10"
    >
      <MapBoxSearch
        class="h-14 w-full text-base text-gray-750 font-bold"
        :name="'to'"
        placeholder="To"
        @get-result="getResult($event, 'to')"
      />
    </div>
    <div class="flex h-14 items-center bg-white rounded-default text-gray-750 border-b border-solid border-gray-400">
      <!-- <base-input placeholder="In 60 minutes" type="datetime-local" :value="time"
             @input="$emit('time', $event)" required></base-input> -->
      <DatePicker
        v-model="localtime"
        type="datetime"
        placeholder="Select date & time"
        @input="$emit('time', $event)"
      />
      <!-- <span class="px-3 bg-white absolute right-0">
              <icon name="time" class="text-2xl text-gray-800" />
            </span> -->
    </div>
    <slot></slot>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SearchRideModule',
  components: {
  },
  computed: {
    ...mapGetters({
      from: 'searcRideForm/from',
      to: 'searcRideForm/to',
      time: 'searcRideForm/time',
      showList: 'searcRideForm/showList'
    })
  },
  props: {},
  data () {
    return {
      localtime: null
    }
  },
  mounted () {},
  methods: {
    getResult (result, name) {
      this.$store.dispatch(`searcRideForm/${name === 'from' ? 'setFrom' : 'setTo'}`, { result: result.result })
    }
  }
}
</script>
