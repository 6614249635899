<template>
  <div class="w-full h-full relative">
    <div
      class="w-full h-full bg-center bg-no-repeat bg-cover"
      style="background-image: url(/img/landing.jpg)"
    />
    <div class="absolute bottom-10 left-0 w-full flex justify-center">
      <Login />
      <!-- <div class="max-w-[200px] w-full relative">
        <div class="flex h-14 items-center bg-white rounded-default text-gray-750 border-b border-solid border-gray-400">
          <input type="text" placeholder="Username" class="w-full h-full outline-none px-8 text-lg" />
        </div>
        <div class="flex h-14 items-center bg-white rounded-default text-gray-750 border-b border-solid border-gray-400">
          <input type="password" placeholder="Password" class="w-full h-full outline-none px-8 text-lg" />
        </div>
        <button class="relative w-full bg-primary-medium text-white font-bold p-2 flex justify-center items-center">
          <div class="text-white w-full">Login</div>
          <div class="absolute left-0 inset-y-0 flex items-center">
            <icon
              name="user"
              class="text-2xl text-white ml-2"
              solid
            />
          </div>
        </button>
      </div> -->
    </div>
  </div>
</template>

<script>

import Login from '../modules/login/_components/Login.vue'

export default {
  name: 'LandingPage',
  components: {
    Login
  }
}
</script>
