<template>
  <!-- <div class="dashboard">
    <Container class="flex justify-center">
      dashboard
    </Container>
  </div> -->
  <ProfileContainer />
</template>

<script>
import ProfileContainer from '@/modules/profile'

export default {
  name: 'Profile',
  components: {
    ProfileContainer
  }
}
</script>
