<template>
  <va-card :data-id="taskId" :data-sort="taskSort" class="list-group-item bg-white m-2 rounded-lg p-0 cursor-pointer" @click.native="$emit('click')">
    <div class="flex justify-between items-center">
      <div class="flex items-center">
        <TypesContainer :selected="type" icon small /><p class="m-0 ml-2">{{ title }}</p>
      </div>
      <div class="flex">
        <div class="mr-2">
          <va-badge type="primary" margin="2" v-if="sprint">{{sprint.name}}</va-badge>
        </div>
        <div class="mr-2">
          <va-badge margin="2">{{estimate}}</va-badge>
        </div>
        <div class="flex justify-between items-center">
          <template v-if="assignees.length > 0">
            <div v-for="assignee in assignees" :key="assignee.id" class="mr-1">
              <Avatar :letter="assignee.first_name.charAt(0)" :color="assignee.color" :name="assignee.full_name" minified />
            </div>
          </template>
          <template v-else>
            <Avatar letter="X" name="Not assigned" class="mr-2" minified />
          </template>
          <strong class="text-xs">{{ branch }}</strong>
        </div>
      </div>
    </div>
  </va-card>
</template>

<script>

import TypesContainer from '@/modules/types'

export default {
  name: 'KanbanItem',
  components: {
    TypesContainer
  },
  props: {
    taskId: {
      default: 0
    },
    sprint: {
      type: Object,
      default: () => {}
    },
    taskSort: {
      default: 0
    },
    estimate: {
      default: 0
    },
    title: {
      default: ''
    },
    type: {
      default: ''
    },
    branch: {
      default: ''
    },
    assignees: {
      default: () => []
    }
  }
}
</script>
