<template>
  <div class="relative w-full border border-gray-400 rounded-md bg-white">
    <select
      :value="value"
      class="appearance-none px-4 py-2 w-full rounded-md bg-transparent"
      @input="$emit('input', $event)"
    >
      <slot></slot>
    </select>
    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 mr-2 text-gray-700">
      <svg
        class="fill-current h-4 w-4"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
      </svg>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BaseSelect',
  props: {
    value: { default: '', required: true }
  }
}
</script>
