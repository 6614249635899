<template>
 <button class="inline-block font-medium leading-6 focus:outline-none" :class="size">Comment</button>
</template>

<script>

export default {
  name: 'CommentButton',
  props: {
    size: { default: 'w-20 ml-10 text-center' }
  }
}
</script>
