<template>
  <div>
    <GiveRideContainer />
    <MapTopGradient />
    <MapBackgroundContainer height-class="h-screen" />
  </div>
</template>

<script>
import GiveRideContainer from '@/modules/giveRide'
import MapBackgroundContainer from '@/modules/mapBackground'
import { mapGetters } from 'vuex'

export default {
  name: 'GiveRide',
  components: {
    GiveRideContainer,
    MapBackgroundContainer
  },
  computed: {
    ...mapGetters({
      selectedRide: 'searcRideForm/selectedRide'
    })
  }
}
</script>
