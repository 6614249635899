<template>
  <div
    v-if="title"
    class="flex justify-between items-center"
  >
    <slot name="title">
      <p class="text-2xl font-bold">
        {{ title }}
      </p>
    </slot>
  </div>
</template>

<script>

export default {
  name: 'ModalHeader',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>
