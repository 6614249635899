<template>
  <div class="w-full h-screen flex items-center">
    <AlertModal
      :create="create"
      :title="title"
      :body="body"
      @toggle="toggle"
    />
    <div
      v-if="loadingLogin"
      class="w-full flex justify-center p-8"
    >
      <Loader />
    </div>
    <Login
      v-if="!isLoggedin && !loadingLogin"
      :email="email"
      :password="password"
      @login="login"
      @facebook-login="facebookLogin"
      @google-login="googleLogin"
      @email="email = $event"
      @password="password = $event"
    />
  </div>
</template>

<script>
import Login from './_components/Login'
import AlertModal from '@/modules/alertModal'

import { mapGetters } from 'vuex'

export default {
  name: 'LoginModule',
  components: {
    Login,
    AlertModal
  },
  mounted: () => {},
  computed: {
    ...mapGetters({
      isLoggedin: 'users/isLoggedin',
      isRegistered: 'users/isRegistered',
      user: 'users/user',
      create: 'users/create',
      title: 'users/title',
      body: 'users/body',
      loadingLogin: 'users/loadingLogin'
    })
  },
  data () {
    return {
      email: '',
      password: ''
    }
  },
  beforeCreate () {},
  methods: {
    login () {
      this.$store.dispatch('users/login', { email: this.email, password: this.password })
    },
    googleLogin () {
      this.$store.dispatch('users/googleLogin')
    },
    facebookLogin () {
      this.$store.dispatch('users/facebookLogin')
    },
    toggle () {
      this.$store.commit('users/SET_ALERT', { create: false })
    }
  }
}
</script>
