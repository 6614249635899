<template>
  <div class="items-center place-content-center">
    <div class="bg-primary-medium h-32">
      <Container padding="p-0">
        <RouterLink
          :to="{name: 'menu'}"
          class="absolute top-2"
        >
          <Icon
            name="menu"
            class="text-3xl mr-2 text-white"
          />
        </RouterLink>
        <Logo icon-class="w-12" />
        <div class="flex items-center place-content-center">
          <RouterLink :to="{ name: 'profile' }">
            <Avatar
              classes="w-24 h-24"
              src="../../../img/profile.png"
              name="Bonnie Green"
            />
          </RouterLink>
        </div>
      </Container>
    </div>

    <div class="bg-white flex flex-col items-center">
      <Container>
        <h5 class="mt-14 text-xl item-center font-bold font-medium text-gray-900 dark:text-white">
          {{ user.name }}
        </h5>
        <div class="flex items-center justify-center">
          <Icon
            name="star"
            class="text-primary-medium"
            solid
          />
          <Icon
            name="star"
            class="text-primary-medium"
            solid
          />
          <Icon
            name="star"
            class="text-primary-medium"
            solid
          />
          <Icon
            name="star"
            class="text-primary-medium"
            solid
          />
          <Icon
            name="star"
            class="text-gray-star"
            solid
          />
          <small class="text-10p mt-1 ml-1 font-bold">57 votes</small>
        </div>
        <div class="bg-gray-100 w-full p-4 mt-4">
          <p class="text-sm font-bold text-primary-medium text-right">
            Edit
          </p>
          <div>
            <div class="p-2 px-3">
              <p class="text-sm font-semibold text-gray-600 leading-none">
                Security
              </p>
              <p class="text-lg font-bold leading-none">
                Standard
              </p>
            </div>
            <hr>
            <div class="p-2 px-3">
              <p class="text-sm font-semibold text-gray-600 leading-none">
                Fuel
              </p>
              <p class="text-lg font-bold leading-none">
                Hybrid
              </p>
            </div>
            <hr>
            <div class="p-2 px-3">
              <p class="text-sm font-semibold text-gray-600 leading-none">
                Seats
              </p>
              <p class="text-lg font-bold leading-none">
                Four
              </p>
            </div>
            <hr>
            <div class="p-2 px-3">
              <p class="text-sm font-semibold text-gray-600 leading-none">
                Fee per milk
              </p>
              <p class="text-lg font-bold leading-none">
                10 SEK
              </p>
            </div>
            <hr>
            <br>
          </div>
        </div>
        <div class="bg-gray-100 w-full p-3 mt-4">
          <p
            v-if="!isEdit"
            class="text-sm font-bold text-primary-medium cursor-pointer text-right"
            @click="$emit('click-edit', $event)"
          >
            Edit
          </p>
          <p
            v-else
            class="text-sm font-bold text-primary-medium cursor-pointer text-right"
            @click="$emit('click-edit', $event)"
          >
            Save
          </p>
          <div>
            <div class="p-2 px-3">
              <p class="text-sm font-semibold text-gray-600 leading-none">
                Email
              </p>
              <p
                v-if="!isEdit"
                class="text-lg font-bold"
              >
                {{ user.email }}
              </p>
              <base-input
                v-else
                type="email"
                placeholder="Email"
                :value="email"
                required
                @input="$emit('email', $event)"
              />
            </div>
            <hr>
            <div class="p-2 px-3">
              <p class="text-sm font-semibold text-gray-600 leading-none">
                Name
              </p>
              <p
                v-if="!isEdit"
                class="text-lg font-bold"
              >
                {{ user.name }}
              </p>
              <base-input
                v-else
                placeholder="Name"
                :value="name"
                required
                @input="$emit('name', $event)"
              />
            </div>
            <hr>
            <br>
            <br>
          </div>
        </div>
      </Container>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Profile',
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    email: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    user: {
      type: Object,
      default: null
    }
  }
}
</script>
