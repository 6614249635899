<template>
  <div class="z-30 absolute inset-0 flex flex-col">
    <hr class="h-2.5 bg-primary-medium ml-0 w-screen absolute top-0">
    <Container padding="p-0">
      <GetRideHeader
        :selected-ride="selectedRide"
        @remove-selected-ride="removeSelectedRide"
      />

      <SendPackage v-if="!selectedRide" />
    </Container>
    <div class="z-20 absolute bottom-0 w-full">
      <Container padding="p-0">
        <SearchRideList />
      </Container>
    </div>
    <ModalContainer
      v-if="selectedRide"
      only-back-with-toggle
      position="absolute"
      background="bg-gray-200"
      full-width
      :padding="false"
      @toggle="removeSelectedRide"
      @on-back-button="removeSelectedRide"
    >
      <Cart
        class="border-t-2 solid border-gray-300 h-full"
      />
    </ModalContainer>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import SendPackage from './_components/SendPackage'
import GetRideHeader from './_components/GetRideHeader'
import ModalContainer from '@/modules/modal'

export default {
  name: 'SendPackageModule',
  components: {
    SendPackage,
    GetRideHeader,
    ModalContainer
  },
  data () {
    return {
      cart: false,
      center: [0, 0],
      map: {}
    }
  },
  computed: {
    ...mapGetters({
      from: 'searcRideForm/from',
      to: 'searcRideForm/to',
      time: 'searcRideForm/time',
      selectedRide: 'searcRideForm/selectedRide',
      showList: 'searcRideForm/showList'
    })
  },
  mounted () {
    this.removeSelectedRide()
  },
  methods: {
    removeSelectedRide () {
      this.$store.dispatch('searcRideForm/getRideResult', [])
      this.$store.dispatch('searcRideForm/setSelectedRide', null)
    }
  }
}
</script>
