<template>
  <div>
    <GetRideContainer />
    <MapTopGradient />
    <MapBackgroundContainer height-class="h-screen" />
  </div>
</template>

<script>
import GetRideContainer from '@/modules/getRide'
import MapBackgroundContainer from '@/modules/mapBackground'
import { mapGetters } from 'vuex'

export default {
  name: 'GetRide',
  components: {
    GetRideContainer,
    MapBackgroundContainer
  },
  computed: {
    ...mapGetters({
      selectedRide: 'searcRideForm/selectedRide'
    })
  }
}
</script>
