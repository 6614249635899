<template>
  <div class="overflow-hidden">
  <OtherUserProfile />
</div>
</template>
<script>
import OtherUserProfile from './_components/OtherUserProfile'
export default {
  name: 'OtherUserProfileModule',
  components: {
    OtherUserProfile
  }
}
</script>
