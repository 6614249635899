<template>
  <va-card :elevation="2" :data-id="id" class="relative list-group-item bg-white m-2 rounded-lg cursor-pointer" @click.native="$emit('click')">
    <va-badge class="absolute top-3 right-3">{{estimate}}</va-badge>
    <strong class="text-xs">{{ branch }}</strong>
    <p class="m-0">{{ title }}</p>
    <div class="flex justify-between mt-4">
      <div class="flex items-center">
        <TypesContainer icon small :selected="type" />
        <p class="text-xs m-0 ml-2">{{date}}</p>
      </div>
      <div class="flex">
        <template v-if="assignees.length > 0">
          <div v-for="assignee in assignees" :key="assignee.id" class="mr-1">
            <Avatar :letter="assignee.first_name.charAt(0)" :color="assignee.color" :name="assignee.full_name" minified />
          </div>
        </template>
        <template v-else>
          <Avatar letter="X" name="Not assigned" class="mr-2" minified />
        </template>
      </div>
    </div>
  </va-card>
</template>

<script>

import TypesContainer from '@/modules/types'

export default {
  name: 'KanbanCard',
  components: {
    TypesContainer
  },
  props: {
    id: {
      default: null
    },
    title: {
      default: ''
    },
    estimate: {
      default: 0
    },
    type: {
      default: ''
    },
    branch: {
      default: ''
    },
    date: {
      default: ''
    },
    assignees: {
      default: () => []
    }
  }
}
</script>
