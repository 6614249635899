<template>
    <div class="flex w-full justify-between px-4 text-center my-4 ">
        <div class="mr-2 text-center font-semibold font-sans text-xs uppercase ">
            <FontAwesomeIcon icon="star" class="text-3xl text-gray-700"/>
            <p>Reputation </p>
            <p>{{Reputation}} </p>
        </div>
        <div class="mr-2 text-center font-semibold font-sans uppercase text-xs">
            <FontAwesomeIcon icon="comment-alt" class="text-3xl text-gray-700"/>
            <p>Feed </p>
            <p>{{Feed}} </p>
        </div>
        <div class=" mr-2 text-center font-semibold font-sans uppercase text-xs">
            <FontAwesomeIcon icon="briefcase" class="text-3xl text-gray-700"/>
            <p>Companies</p>
            <p>{{Companies}} </p>
        </div>
        <div class="text-center font-semibold font-sans uppercase text-xs">
            <FontAwesomeIcon icon="calendar-check" class="text-3xl text-gray-700"/>
            <p>Member</p>
            <p>{{Member}} </p>
        </div>
    </div>
</template>
<script>

export default {
  name: 'ProfileSection',
  props: {
    Reputation: { default: '' },
    Feed: { default: '' },
    Companies: { default: '' },
    Member: { default: '' }
  }
}
</script>
