<template>
  <div
    ref="modal"
    @keyup.esc="toggle"
    tabindex="0"
    class="modal fixed w-full h-full max-h-full top-0 left-0 p-4 flex items-center justify-center z-50"
  >
    <div
      @click="toggle"
      class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
    ></div>

    <div class="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50  max-h-full overflow-y-auto">

      <div
        @click="toggle"
        class="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50"
      >
        <svg
          class="fill-current text-white"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="modal-content py-4 text-left px-6">
        <!--Title-->
        <div class="flex justify-between items-center pb-3">
          <slot name="title">
            <p class="text-2xl font-bold">{{title}}</p>
          </slot>
          <div
            @click="toggle"
            class="modal-close cursor-pointer z-50"
          >
            <svg
              class="fill-current text-black"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
            </svg>
          </div>
        </div>

        <!--Body-->
        <slot>
          <p>Modal content can go here</p>
          <p>...</p>
          <p>...</p>
          <p>...</p>
          <p>...</p>
        </slot>
        <div class="flex justify-end pt-2">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Modal',
  props: {
    title: { default: '' }
  },
  methods: {
    toggle () {
      this.$emit('toggle')
    }
  },
  mounted () {
    this.$refs.modal.focus()
    this.$emit('focus')
  }
}
</script>
