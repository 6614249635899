import Vue from 'vue'
import App from './App.vue'
import router from './js/router'
import { i18n } from '@/js/language/index.js'
import store from './js/store'
import Va from 'vue-atlas'
import 'vue-atlas/dist/vue-atlas.css'
import './js/autoload'
import '@/assets/css/tailwind.css'
import './registerServiceWorker'
import PortalVue from 'portal-vue'

Vue.config.productionTip = false

Vue.use(Va, 'en')
Vue.use(PortalVue)

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')

// Vue.component('PostContainer', PostContainer)
