<template>
  <!-- <div class="dashboard">
    <Container class="flex justify-center">
      dashboard
    </Container>
  </div> -->
  <OtherUserProfileContainer />
</template>

<script>
import OtherUserProfileContainer from '@/modules/otherUserProfile'

export default {
  name: 'OtherUserProfile',
  components: {
    OtherUserProfileContainer
  }
}
</script>
