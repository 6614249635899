<template>
  <div class="editor">
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <div class="menubar">

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.bold() }"
          @click="commands.bold"
        >
          <FontAwesomeIcon icon="bold" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.italic() }"
          @click="commands.italic"
        >
          <FontAwesomeIcon icon="italic" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.strike() }"
          @click="commands.strike"
        >
          <FontAwesomeIcon icon="strikethrough" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.code() }"
          @click="commands.code"
        >
          <FontAwesomeIcon icon="code" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 2 }) }"
          @click="commands.heading({ level: 2 })"
        >
          H1
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.bullet_list() }"
          @click="commands.bullet_list"
        >
          <FontAwesomeIcon icon="list-ul" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.ordered_list() }"
          @click="commands.ordered_list"
        >
          <FontAwesomeIcon icon="list-ol" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.code_block() }"
          @click="commands.code_block"
        >
          <FontAwesomeIcon icon="code" />
        </button>

        <button
          class="menubar__button"
          @click="commands.undo"
        >
          <FontAwesomeIcon icon="undo" />
        </button>

        <button
          class="menubar__button"
          @click="commands.redo"
        >
          <FontAwesomeIcon icon="redo" />
        </button>

      </div>
    </editor-menu-bar>

    <editor-content class="editor__content" :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History
} from 'tiptap-extensions'

export default {
  components: {
    EditorContent,
    EditorMenuBar
  },
  props: {
    content: {
      default: ''
    }
  },
  data () {
    return {
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History()
        ],
        onUpdate: ({ getHTML }) => {
          this.$emit('change', getHTML())
        }
      })
    }
  },
  mounted () {
    this.editor.setContent(this.content)
  },
  beforeDestroy () {
    this.editor.destroy()
  }
}
</script>

<style lang="scss">
.editor {
  border: 2px solid #e0e2e6;

  .ProseMirror {
    @apply py-2;
    @apply px-4;
    min-height: 10rem;
  }
}
.menubar {
  border-bottom: 1px solid #ccc;

  button.menubar__button {
    color: #000;
    font-weight: 700;

    position: relative;
    top: auto;
    width: 35px;
    height: 35px;
    margin:0;

    background: #fff;
    border-right: 1px solid #ccc;

    &.is-active {
      border-color: #ccc;
    }

    .material-icons {
      font-size: 17px;
      color: #000;
    }

    &:last-child {
      margin: 0;
    }
  }
}
</style>
