<template>
  <div
    class="flex justify-end"
    :class="[padding ? 'pt-2' : '']"
  >
    <slot name="footer" />
  </div>
</template>

<script>

export default {
  name: 'ModalFooter',
  props: {
    padding: {
      type: Boolean,
      default: true
    }
  }
}
</script>
