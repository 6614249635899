import { get, put, post, destroy } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}`

const login = async (data) => post(`${url}/login`, data)
const guest = async (data) => get(`${url}/guest`, data)
const csrf = async (data) => get(`${process.env.VUE_APP_API_DOMAIN}/sanctum/csrf-cookie`, data)
const register = async (data) => post(`${url}/register`, data)
const firebaseRegister = async (data) => post(`${url}/register/firebase`, data)
const one = async (id) => post(`${url}/users/${id}`)
const follow = async (id) => post(`${url}/users/${id}/follow`)
const block = async (id) => post(`${url}/users/${id}/block`)
const profileImage = async (data) => post(`${url}/profile-image`, data)
const profile = async () => get(`${url}/me`)

const roles = async () => get(`${url}/roles`)
const all = async () => get(`${url}/users`)
const add = async (data) => post(`${url}/users`, data)
const update = async (userId, data) => put(`${url}/users/${userId}`, data)
const remove = async (userId) => destroy(`${url}/users/${userId}`)

export default {
  roles,
  login,
  guest,
  csrf,
  register,
  one,
  firebaseRegister,
  follow,
  block,
  profileImage,
  profile,
  all,
  add,
  update,
  remove
}
