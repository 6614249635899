<template>
  <CartDepositContainer />
</template>

<script>
import CartDepositContainer from '@/modules/cartDeposit'

export default {
  name: 'CartDeposit',
  components: {
    CartDepositContainer
  }
}
</script>
