<template>
  <RouterLink :to="{ name: 'ride' }">
    <div class="flex place-content-center mb-5">
      <div class="mt-3.5 m-1">
        <img :class="iconClass" :src="small ? '/logo_small.png' : '/logo.png'" />
      </div>
    </div>
  </RouterLink>
</template>

<script>
export default {
  name: 'Logo',
  props: {
    iconClass: { default: 'h-10 w-10' },
    small: { type: Boolean, default: false },
    textClass: { default: 'text-white text-center text-5xl' }
  }
}
</script>
