<template>
  <CurrentCredit />
</template>
<script>
import CurrentCredit from './_components/CurrentCredit'
export default {
  name: 'CurrentCreditModule',
  components: {
    CurrentCredit
  }
}
</script>
