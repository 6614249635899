<template>
  <div
    :class="[background,padding,rounded,shadow]"
    class="flex flex-col"
  >
    <div
      v-if="slotExist('top')"
      class="mt-4 p-4 flex justify-between items-center"
    >
      <slot name="top" />
    </div>
    <div
      v-if="slotExist('body')"
      class="flex flex-col flex-grow"
    >
      <slot name="body" />
    </div>
    <div
      v-if="slotExist('left')"
      class="flex flex-col sm:flex-row"
      :class="'justify-'+justify+' items-'+items"
    >
      <div>
        <slot name="left" />
      </div>
      <div v-if="slotExist('right')">
        <slot name="right" />
      </div>
    </div>
    <div
      v-if="slotExist('bottom')"
      class="mt-4 p-4 flex flex-col sm:flex-row justify-between items-center"
    >
      <slot name="bottom" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'BaseCard',
  props: {
    padding: { default: 'p-4' },
    justify: { default: 'start' },
    items: { default: 'start' },
    background: { default: 'bg-white' },
    rounded: { default: 'rounded-md' },
    shadow: { default: 'shadow' }
  },
  methods: {
    slotExist (type) {
      return this.$slots[type] !== undefined && this.$slots[type].length > 0
    }
  }
}
</script>
