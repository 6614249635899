
export default {
  state: {
    events: [
      {
        uuid: 'e2dc474c-4969-40ee-a564-be4d6ed7f5b1',
        name: 'Benjamin Ingrosso',
        background: '../../../img/konsert.jpeg',
        profile: '../../../img/b.jpg',
        locations: [
          {
            uuid: 'e2dc175c-4969-40ee-a564-be4d6ad7f5b2',
            name: 'Ullevi',
            to: 'Ullevi',
            date: '2024-05-26 11:00',
            geometry: {
              'coordinates': [11.987286, 57.7058615],
              'type': 'Point'
            }
          },
          {
            uuid: 'e2dc175c-4969-40ee-a564-beasdad7f5b2',
            name: 'Kalmar',
            to: 'Kalmar',
            date: '2024-06-15 08:00',
            'geometry': {
              'coordinates': [16.337453, 56.678356],
              'type': 'Point'
            }
          }
        ]
      },
      {
        uuid: 'e2dc474c-4969-40ee-a564-be4d6ed7f5b2',
        name: 'Skistar',
        background: '../../../img/skistar.jpg',
        profile: '../../../img/skistarlogo.jpg',
        locations: [
          {
            uuid: 'e2dc175c-4969-40ee-a564-be4d6ad7f5b2',
            name: 'Sälen',
            to: 'Fjällvägen 25',
            date: '2024-05-26 11:00',
            'geometry': {
              'coordinates': [13.18709, 61.164318],
              'type': 'Point'
            }
          }
        ]
      }
    ]
  },
  getters: {
    events: (state) => state.events
  },
  actions: {
  },
  namespaced: true
}
