<template>
  <div>
    <Modal v-if="create" @toggle="$emit('toggle')">
        <template v-slot:title>
           <p class="w-full text-center text-xl">{{title}}</p>
        </template>
        <p class="text-sm text-center">{{body}}</p>
        <p v-if="showConfirmButton" class="text-right mt-8 text-xs">
          <PositiveButton @click.native="$emit('agree')" class="border border-black ml-3">Agree</PositiveButton>
          <BaseButton @click="$emit('toggle')" class="border border-black ml-3">cancel</BaseButton>
        </p>
    </Modal>
  </div>
</template>

<script>
export default {
  name: 'AlertModal',
  props: {
    create: { default: false },
    title: { default: '' },
    body: { default: '' },
    showConfirmButton: { default: false }
  }
}
</script>
