<template>
  <div class="w-full text-xl ml-2 mt-2 font-semibold text-gray-600">
    <div class="inline-block"><slot></slot></div>
    <button @click="$emit('click')" class="inline-block mr-4 float-right text-shadow-none focus:outline-none"><FontAwesomeIcon icon="plus" /></button>
  </div>
</template>

<script>

export default {
  name: 'TitleWithAddButton'
}
</script>
