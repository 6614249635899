<template>
  <div
    class="relative bg-green-100 p-4 rounded-lg my-4 text-green-700 border border-green-700 flex items-center"
  >
    <i
      class="material-icons absolute top-0 right-0 text-xl cursor-pointer p-2"
      v-if="canClose"
      @click="$emit('close')"
      >close</i
    >
    <FontAwesomeIcon icon="check-circle" class="text-2xl mr-4" />
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SuccessMessage',
  props: {
    canClose: { default: false }
  }
}
</script>
