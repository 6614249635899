<template>
  <SignupContainer/>
</template>

<script>

import SignupContainer from '@/modules/signup'

export default {
  name: 'Signup',
  components: {
    SignupContainer
  }
}
</script>
