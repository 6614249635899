<template>
  <div class="flex mt-2 w-full">
    <div class="mb-1">
      <span class="float-left -m-1 mr-0.5"><AvatarSlot :src="src" :size="'w-6 h-6'"/></span>
      <span class="text-xs font-bold mx-1">{{name}}</span>
      <span class="text-gray-600 text-xs mx-1">{{comment}}</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AvatarNameComment',
  props: {
    src: { default: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png' },
    name: { default: '' },
    comment: { default: '' }
  }
}
</script>
