import Vue from 'vue'
import Router from 'vue-router'
import auth from './helpers/auth'
import store from './store'

import Login from '@/views/Login'
import Logout from '@/views/Logout'
import Signup from '@/views/Signup'
import Ride from '@/views/Ride'
import GetRide from '@/views/GetRide'
import GiveRide from '@/views/GiveRide'
import SendPackage from '@/views/SendPackage'
import Confirm from '@/views/Confirm'
import CartDeposit from '@/views/CartDeposit'
import CurrentCredit from '@/views/CurrentCredit'
import Menu from '@/views/Menu'
import Home from '@/views/Home'
import Profile from '@/views/Profile'
import MyRides from '@/views/MyRides'
import Payments from '@/views/Payments'
import Bookings from '@/views/Bookings'
import Events from '@/views/Events'
import PaymentMethod from '@/views/PaymentMethod'
import LandingPage from '@/views/LandingPage'
import OtherUserProfile from '@/views/OtherUserProfile'
// import LoggedInLayout from '@/layout/LoggedInLayout'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: 'active',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '',
      component: LandingPage
    },
    {
      path: '/paymentmethod',
      name: 'payment-method',
      component: PaymentMethod
    },
    {
      path: '/ride',
      name: 'ride',
      component: Ride
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile
    },
    {
      path: '/payments/:id',
      name: 'payments-single',
      component: Payments
    },
    {
      path: '/payments',
      name: 'payments',
      component: Payments
    },
    {
      path: '/bookings/:id',
      name: 'bookings-single',
      component: Bookings
    },
    {
      path: '/bookings',
      name: 'bookings',
      component: Bookings
    },
    {
      path: '/events/:id',
      name: 'events-single',
      component: Events
    },
    {
      path: '/events',
      name: 'events',
      component: Events
    },
    {
      path: '/payment-method',
      name: 'paymentMethod',
      component: PaymentMethod
    },
    {
      path: '/my-rides',
      name: 'myRides',
      component: MyRides
    },
    {
      path: '/other-profile',
      name: 'other-profile',
      component: OtherUserProfile
    },
    {
      path: '/home',
      name: 'home',
      component: Home
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout
    },
    {
      path: '/signup',
      name: 'signup',
      component: Signup
    },
    {
      path: '/get-ride',
      name: 'get-ride',
      component: GetRide

    },
    {
      path: '/send-package',
      name: 'send-package',
      component: SendPackage

    },
    {
      path: '/give-ride',
      name: 'give-ride',
      component: GiveRide
    },
    {
      path: '/confirm',
      name: 'confirm',
      component: Confirm
    },
    {
      path: '/current-credit',
      name: 'current-credit',
      component: CurrentCredit
    },
    {
      path: '/cart-deposit',
      name: 'cart-deposit',
      component: CartDeposit
    },
    {
      path: '/menu',
      name: 'menu',
      component: Menu
    }
  ]
})

router.beforeEach((to, from, next) => {
  // store.dispatch('todos/reset')

  if (auth.isAuthenticated()) {
    store.dispatch('users/fetch')
  }

  const tryToAccessUserProtectedUrlWithoutAccess =
    to.matched.some((m) => m.meta.protected) && !auth.isAuthenticated()

  if (tryToAccessUserProtectedUrlWithoutAccess) {
    auth.clearToken()
    next({ name: 'login' })
  } else {
    next()
  }

  next()
})

export default router
