<template>
  <!-- <input :type="type" :value="value" @keyup.enter="$emit('submit')" @input="$emit('input', $event)" class="px-4 py-2 rounded-md border bg-gray-200 border-gray-800 focus:outline-none" /> -->
  <va-input :type="type" class="w-full h-full text-base text-primary-dark" :placeholder="placeholder" :value="value"
    @keyup.enter="$emit('submit')" @input="$emit('input', $event)" :required="required" />
</template>

<script>

export default {
  name: 'BaseInput',
  props: {
    placeholder: { default: '' },
    type: { default: 'text' },
    value: { default: '' },
    required: { default: false }
  }
}
</script>

<style>
.va-form-control {
  max-height: inherit;
  height: inherit;
  font-size: inherit;
  color: inherit;
  border: none;
}
</style>
