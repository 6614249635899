<template>
  <div
    v-if="selectedRide"
    class=""
  >
    <div class="w-full flex items-center place-content-center bg-gray-200">
      <div class="flex justify-center items-center border-b w-full bg-gray-200 border-gray-hr">
        <div class="w-42 flex space-x-6 py-5">
          <RouterLink :to="{ name: 'other-profile' }">
            <img
              src="../../public/img/profile.png"
              class="rounded-full w-16 h-16"
            >
          </RouterLink>
          <div class="">
            <p class="text-sm font-bold">
              {{ selectedRide.name }}
            </p>
            <small class="text-xs font-semibold text-gray-400">{{ selectedRide.vehicle }}</small>
            <div class="flex items-center">
              <Rating />
              <small class="text-10p mt-1 font-bold">{{ selectedRide.votes }} votes</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray-200 w-full place-content-center">
      <Container>
        <div class="items-center place-content-center py-3">
          <div class="flex flex-row items-center place-content-center space-x-3">
            <div class="items-center place-content-center">
              <p class="flex place-content-center font-bold">
                Carl-Marcus is going on
              </p>
              <p class="text-lg font-bold text-base text-gray-750 leading-none">
                Stockholm to Norrköping
              </p>
              <p class="flex text-sm text-primary-medium place-content-center font-bold">
                Thursday 10:10
              </p>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-center mt-2 space-x-1">
          <RouterLink
            :to="{ name: 'current-credit' }"
            class="w-1/2"
          >
            <div class="p-2 w-full bg-primary-medium flex items-center place-content-center">
              <Icon
                name="car"
                class="text-white text-xl mr-2"
                solid
              />
              <div class="mr-4">
                <p class="flex place-content-center text-base text-white font-bold">
                  4 Seat Left
                </p>
                <p class="flex place-content-center text-10p font-bold leading-none">
                  Book a seat here
                </p>
              </div>
            </div>
          </RouterLink>
          <div class="p-2 w-1/2 bg-white flex items-center place-content-center p-2">
            <div class="h-6 mb-2">
              <p class="text-xl font-bold leading-none">
                {{ selectedRide.amount }} kr
              </p>
              <p class="text-10p font-bold text-primary-medium leading-none">
                80% MATCH
              </p>
            </div>
            <Icon
              class="font-bold text-base"
              name="bx bx-right-arrow-alt"
              solid
            />
          </div>
        </div>
        <div class="flex items-center justify-start mt-7">
          <span class="inline-flex font-bold py-2 text-lg">
            Driver ratings
            <Icon
              name="down-arrow"
              class="text-[8px] mx-3 mt-1"
              solid
            />
          </span>
        </div>
        <div>
          <carousel
            :per-page="1"
            :mouse-drag="true"
            :center-mode="false"
            :adjustable-height="true"
            pagination-color="#d1d5db"
            pagination-active-color="#2fac68"
            :pagination-size="10"
          >
            <slide>
              <ProfileCard />
            </slide>
            <slide>
              <ProfileCard />
            </slide>
            <slide>
              <ProfileCard />
            </slide>
            <slide>
              <ProfileCard />
            </slide>
            <slide>
              <ProfileCard />
            </slide>
            <slide>
              <ProfileCard />
            </slide>
          </carousel>
        </div>
        <!-- <div class="flex space-x-1 mt-3 place-content-center">
        <small class="relative bg-primary-medium rounded-full w-2 h-2"></small>
        <small class="relative bg-gray-300 rounded-full w-2 h-2"></small>
        <small class="relative bg-gray-300 rounded-full w-2 h-2"></small>
        <small class="relative bg-gray-300 rounded-full w-2 h-2"></small>
        <small class="relative bg-gray-300 rounded-full w-2 h-2"></small>
        <small class="relative bg-gray-300 rounded-full w-2 h-2"></small>
        <small class="relative bg-gray-300 rounded-full w-2 h-2"></small>
      </div> -->
        <div class="text-lg mb-1">
          Driver Data
        </div>
        <div class="flex space-x-1 place-content-center">
          <div class="bg-gray-100 w-full">
            <div class="p-1 px-3 border-b border-gray-hr">
              <small class="text-xs font-semibold text-gray-400 leading-none">Security </small>
              <p class="text-base font-bold leading-none">
                Standard
              </p>
            </div>
            <div class="p-1 px-3 border-b border-gray-hr">
              <small class="text-xs font-semibold text-gray-400 leading-none">Fuel</small>
              <p class="text-base font-bold leading-none">
                Hybrid
              </p>
            </div>
            <div class="p-1 px-3 border-b border-gray-hr">
              <small class="text-xs font-semibold text-gray-400 leading-none">Seats</small>
              <p class="text-base font-bold leading-none">
                Four
              </p>
            </div>
            <div class="p-3 px-3 item-center place-content-center">
              <div class="flex items-center place-content-center">
                <p class="text-base font-bold leading-none">
                  More
                </p>
                <Icon
                  name="down-arrow"
                  class="text-10p"
                  solid
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { Carousel, Slide } from 'vue-carousel'
import ProfileCard from './ProfileCard.vue'
export default {
  name: 'Cart',
  components: {
    Carousel,
    Slide,
    ProfileCard
  },
  props: {
    email: {
      type: String,
      default: ''
    },
    password: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      selectedRide: 'searcRideForm/selectedRide'
    })
  }
}
</script>
