<template>
  <div class="mt-4 h-full">
    <div class="flex justify-center relative h-full">
      <SearchRideContainer />
    </div>
  </div>
</template>
<script>
import SearchRideContainer from '@/modules/searchRide/'

export default {
  name: 'GetRide',
  components: {
    SearchRideContainer
  },
  props: {}
}
</script>
