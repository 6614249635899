<template>
  <div class="flex items-center justify-center">
    <div
      :style="{ 'background-color': background }"
      class="space-y-4 w-full shadow-sm item-center justify-center border-b border-gray-hr"
    >
      <div class="flex justify-center m-3 space-x-5">
        <div class="flex justify-center h-16 m-2">
          <div class="m-2">
            <Avatar
              src="../../../img/profile.png"
              classes="w-12 h-12"
              name="Christoffer Johansson"
            />
          </div>
          <div class="m-1">
            <p class="text-xs font-bold">
              Christoffer Johansson
            </p>
            <div class="flex items-center">
              <Rating />
            </div>
            <p class="text-10p font-bold">
              Very good driver ,safe and fun to talk too
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ProfileCard',
  props: {
    background: { type: String, default: 'white' }
  }
}
</script>
