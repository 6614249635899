<template>
  <BaseButton @click="$emit('click', $event)" class="bg-gray-500 hover:bg-gray-700 text-gray-200">
    <slot></slot>
  </BaseButton>
</template>

<script>

export default {
  name: 'DisabledButton'
}
</script>
