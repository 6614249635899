<template>
  <div class="relative block pt-4 overflow-hidden">
    <video class="w-full" controls>
      <source :src="src">
    </video>
  </div>
</template>

<script>
export default {
  name: 'BaseVideo',
  props: {
    src: { default: '' }
  }
}
</script>
