<template>
  <div class="bg-gray-200 h-screen flex flex-col items-center border-gray-400 border-t-[7px] border-t-primary-medium">
    <Container class="h-full">
      <div class="w-full">
        <div class="flex items-center">
          <RouterLink :to="{ name: 'ride' }" class="flex items-center">
            <Icon
              class="font-bold text-base cursor-pointer"
              name="arrow-back"
            />
            <div class="flex items-center place-content-center">
              <p class="text-xs font-bold text-base text-gray-750 ml-4">
                Back to Home
              </p>
            </div>
          </RouterLink>

        </div>
      </div>
      <div class="h-full bg-gray-200 w-full flex items-center place-content-center">
        <div class="flex flex-col items-center justify-center w-full">
          <icon
            name="check-circle"
            class="text-5xl mr-4 text-primary-medium place-content-center"
            solid
          />
          <p class="text-xs font-medium font-bold text-base text-gray-750">
            Confirmed Seat booked
          </p>
          <div class="w-full shadow-lg bg-white flex justify-center mt-10">
            <div class="px-6 py-4 h-26 space-y-0">
              <p class="text-xs font-bold leading-none mb-1">
                you are going to with Marcus from
              </p>
              <p class="text-xs font-bold text-base text-gray-750 leading-none">
                Stockholm to Norrköping
              </p>
              <small class="text-xs font-bold text-primary-medium leading-none">Thursday 10:00 </small>
              <p class="text-lg font-bold ml-48 mt-1 leading-none">
                150 kr
              </p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
export default {
  name: 'Confirm',
  mounted () {
    // JUST FOR SHOW:
    this.$store.dispatch('users/addRide', null)

    this.$store.dispatch('users/addRide', {
      uuid: 'e2dc175c-4969-40ee-a564-be4d6uk7f5b4',
      name: 'Marcus Aman',
      amount: 150,
      currency: 'SEK',
      from: 'Söderköping',
      to: 'Norrköping',
      date: '2023-11-24',
      type: 'card',
      status: 'PAID'
    })
  }
}
</script>
