<template>
  <MenuContainer />
</template>

<script>
import MenuContainer from '@/modules/menu'

export default {
  name: 'Menu',
  components: {
    MenuContainer
  }
}
</script>
