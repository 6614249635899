// import { RepositoryFactory } from '@/api/repositoryFactory'
// const repository = RepositoryFactory.get('rides')

import { rides } from '../_mock_data/rides'

export default {
  state: {
    from: {},
    to: {},
    time: null,
    loading: false,
    rideList: [],
    showList: false,
    selectedRide: null
  },
  getters: {
    from: state => state.from,
    to: state => state.to,
    time: state => state.time,
    loading: state => state.loading,
    rideList: state => state.rideList,
    showList: state => state.showList,
    selectedRide: state => state.selectedRide
  },
  mutations: {
    SET_FROM (state, payload) {
      state.from = payload
    },
    SET_TO (state, payload) {
      state.to = payload
    },
    SET_TIME (state, payload) {
      state.time = payload
    },
    SET_LOADING (state, payload) {
      state.loading = payload
    },
    SET_RIDELIST (state, payload) {
      state.rideList = payload
    },
    SET_SHOWLIST (state, payload) {
      state.showList = payload
    },
    SET_SELECTEDRIDE (state, payload) {
      state.selectedRide = payload
    }
  },
  actions: {
    async setFrom ({ commit }, payload) {
      commit('SET_FROM', payload.result)
    },
    async setTo ({ commit }, payload) {
      commit('SET_TO', payload.result)
    },
    async setTime ({ commit }, payload) {
      commit('SET_TIME', payload.result)
    },
    async setLoading ({ commit }, payload) {
      commit('SET_LOADING', payload)
    },
    async getRideResult ({ commit }, payload = null) {
      try {
        commit('SET_LOADING', true)
        // const response = await repository.all()
        // commit('SET_LOADING', false)
        // if ((response.status === 200) && response.data) {
        if (payload === null) {
          commit('SET_RIDELIST', rides)
        } else {
          commit('SET_RIDELIST', payload)
        }
        commit('SET_SHOWLIST', true)
        // }
      } catch (e) {
        commit('SET_LOADING', false)
      }
    },
    async setShowList ({ commit }, payload) {
      commit('SET_SHOWLIST', payload)
    },
    async setSelectedRide ({ commit }, payload) {
      commit('SET_SELECTEDRIDE', payload)
    }
  },
  namespaced: true
}
