<template>
  <div
    key="modal-content"
    class="modal-content w-full h-full text-left overflow-hidden overflow-y-auto"
    :class="[padding ? 'py-4 px-6' : '', modalClass]"
    @click.stop
  >
    <slot />
  </div>
</template>

<script>

export default {
  name: 'ModalContent',
  props: {
    padding: {
      type: Boolean,
      default: true
    },
    modalClass: {
      type: String,
      default: ''
    }
  }
}
</script>
