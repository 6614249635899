<template>
  <div class="bg-gray-200 h-screen flex flex-col items-center border-gray-400 border-t-[7px] border-t-primary-medium">
    <!-- <hr class="h-2.5 bg-primary-medium absolute top-0" /> -->
    <Container class="h-full">
      <div class="w-full">
        <div class="flex items-center">
          <RouterLink :to="{ name: 'ride' }">
            <Icon
              class="font-bold text-base cursor-pointer"
              name="arrow-back"
            />
          </RouterLink>
          <div class="flex items-center justify-center">
            <p class="text-xs font-bold text-base text-gray-750 ml-4">
              Back
            </p>
          </div>
        </div>
      </div>
      <div class="h-full bg-gray-200 w-full flex items-center justify-center">
        <div class="items-center justify-center w-full">
          <div class="flex flex-row items-center justify-center space-x-3">
            <icon
              name="wallet"
              class="text-2xl text-gray-400 justify-center"
              solid
            />
            <div class="items-center justify-center">
              <small class="text-10p font-bold text-gray-400 leading-none">Your current credits </small>
              <p class="text-lg font-bold text-base text-gray-750 leading-none">
                28 SEK
              </p>
            </div>
          </div>

          <button class="bg-gray-750 my-20 w-full text-gray-800 font-bold p-2 flex justify-center mt-4">
            <div class="flex items-center">
              <icon
                name="credit-card-front"
                class="text-2xl text-white mr-16 ml-2"
                solid
              />
              <span class="text-white mr-24">Deposit money</span>
            </div>
          </button>

          <div class="rounded shadow-lg bg-white w-full flex justify-center">
            <div class="px-6 py-4 h-26 space-y-0">
              <p class="text-xs font-bold">
                You are going to with Marcus from
              </p>
              <p class="font-bold text-base text-gray-750 leading-none">
                Stockholm to Norrköping
              </p>
              <small class="text-xs font-bold text-primary-medium leading-none">Thursday 10:00 </small>
              <div class="flex space-x-1">
                <p class="text-lg font-bold ml-48 mt-1 leading-none">
                  150 kr
                </p>
                <Icon
                  class="font-bold text-base transform rotate-180"
                  name="bx bx-arrow-back"
                  solid
                />
              </div>
            </div>
          </div>
          <RouterLink :to="{ name: 'confirm' }">
            <button class="bg-primary-medium w-full text-gray-800 font-bold p-2 flex justify-center mt-1">
              <div class="flex items-center">
                <icon
                  name="check-circle"
                  class="text-2xl text-white mr-16"
                  solid
                />
                <span class="text-white text-base mr-24">Confirm seat</span>
              </div>
            </button>
          </RouterLink>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
export default {
  name: 'CurrentCredit'
}
</script>
