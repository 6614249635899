import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('questions')

export default {
  state: {
    questions: {},
    answeredQuestions: {},
    loading: false,
    loadingNew: false,
    loadingActivate: false,
    loadingRating: false
  },
  getters: {
    loading: state => state.loading,
    loadingNew: state => state.loadingNew,
    loadingActivate: state => state.loadingActivate,
    loadingRating: state => state.loadingRating,
    questions: state => Object.values(state.questions),
    answeredQuestions: state => Object.values(state.answeredQuestions)
  },
  mutations: {
    SET_LOADING (state, boolean) {
      state.loading = boolean
    },
    SET_LOADING_NEW (state, boolean) {
      state.loadingNew = boolean
    },
    SET_LOADING_ACTIVATE (state, boolean) {
      state.loadingActivate = boolean
    },
    SET_LOADING_RATING (state, boolean) {
      state.loadingRating = boolean
    },
    REMOVE_QUESTION (state, questionId) {
      delete state.questions[questionId]
      state.questions = { ...state.questions }
    },
    SET_LIST (state, payload) {
      payload.map(question => {
        state.questions = { ...state.questions, [question.id]: question }
      })

      state.questions = { ...state.questions }
    },
    SET_ANSWERS (state, payload) {
      payload.map(answeredQuestion => {
        state.answeredQuestions = { ...state.answeredQuestions, [answeredQuestion.question_id]: answeredQuestion }
      })

      state.questions = { ...state.questions }
    }
  },
  actions: {
    async fetchAll ({ commit }) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.all()
        commit('SET_LOADING', false)
        if ((response.status === 200) && response.data) {
          commit('SET_LIST', response.data)
        }
      } catch (e) {
        commit('SET_LOADING', false)
      }
    },
    async fetchPerUser ({ commit }) {
      try {
        commit('SET_LOADING', true)
        const response = await repository.byUser()
        commit('SET_LOADING', false)
        if ((response.status === 200) && response.data) {
          commit('SET_LIST', response.data.questions)
          commit('SET_ANSWERS', response.data.answeredQuestions)
        }
      } catch (e) {
        commit('SET_LOADING', false)
      }
    },
    async add ({ state, commit }, payload) {
      commit('SET_LOADING_NEW', true)
      commit('SET_LOADING', true)
      let response
      if (payload.id) {
        response = await repository.update(payload.id, payload)
      } else {
        response = await repository.add(payload)
      }
      commit('SET_LIST', [response.data])
      commit('SET_LOADING_NEW', false)
      commit('SET_LOADING', false)
    },
    async activate ({ state, commit }, question) {
      commit('SET_LOADING_ACTIVATE', true)
      const response = await repository.activate(question.id)
      commit('SET_LIST', [response.data])
      commit('SET_LOADING_ACTIVATE', false)
    },
    async rate ({ state, commit }, payload) {
      commit('SET_LOADING_RATING', true)
      const response = await repository.rate(payload)
      if ((response.status === 200) && response.data) {
        commit('SET_LIST', response.data.questions)
        commit('SET_ANSWERS', response.data.answeredQuestions)
      }
      commit('SET_LOADING_RATING', false)
    }
  },
  namespaced: true
}
