<template>
  <Menu />
</template>
<script>
import Menu from './_components/Menu'
export default {
  name: 'MenuModule',
  components: {
    Menu
  }
}
</script>
