<template>
  <div class="h-full z-30 relative">
    <hr class="h-2.5 bg-primary-medium ml-0 w-screen absolute top-0">
    <div class="w-full flex flex-col items-center">
      <div class="absolute top-0 z-2 w-full">
        <Container>
          <div class="h-11m my-7 w-full">
            <div class="flex items-center">
              <RouterLink :to="{name: 'menu'}">
                <Icon
                  name="menu"
                  class="text-3xl mr-2 text-primary-medium"
                />
              </RouterLink>
              <div
                class="rounded-full dark:bg-gray-500 aspect-square w-10 h-8"
                @click="$emit('menu')"
              >
                <Avatar
                  src="../../../img/profile.png"
                  name="christopher"
                />
              </div>
              <div class="w-full ml-2">
                <p class="text-xs font-bold">
                  Good morning, <span class="text-primary-medium">{{ user.name }}</span>
                </p>
                <p class="text-xs font-bold text-base">
                  Whats up today?
                </p>
              </div>
            </div>
          </div>

          <div class="mt-10 mb-6" v-if="$store.getters['users/confirmedRides'].length > 0">
            <div class="text-xs mb-1 font-semibold">Upcoming events</div>
            <div class="w-full shadow-lg bg-white flex justify-center">
              <div class="px-6 py-4 h-26 space-y-0">
                <p class="text-xs font-bold leading-none mb-1">
                  you are going to with {{$store.getters['users/confirmedRides'][0].name}} from
                </p>
                <p class="text-xs font-bold text-base text-gray-750 leading-none">
                  {{$store.getters['users/confirmedRides'][0].from}} to {{$store.getters['users/confirmedRides'][0].to}}
                </p>
                <small class="text-xs font-bold text-primary-medium leading-none">{{$store.getters['users/confirmedRides'][0].date}} </small>
              </div>
            </div>
            <div>
              <RouterLink :to="{name: 'bookings'}" class="flex items-center justify-end pt-2 font-bold"><span>See all bookings</span> <Icon name="chevron-right" /></RouterLink>
            </div>
          </div>
          <div class="px-1 flex flex-col space-y-4">
            <div class="h-52p border bg-gray-200 border-gray-400 rounded">
              <RouterLink :to="{ name: 'get-ride' }">
                <div class="flex justify-between">
                  <p class="text-xs p-3 font-bold text-base mr-1 text-gray-750">
                    Get a ride
                  </p>
                  <Icon
                    name="bx bx-search text-base float-right pr-5"
                    solid
                  />
                </div>
              </RouterLink>
            </div>
            <div class="h-52p border bg-gray-200 border-gray-400 rounded">
              <RouterLink :to="{ name: 'give-ride' }">
                <div class="flex justify-between">
                  <p class="text-xs p-3 font-bold text-base text-gray-750">
                    Give a ride
                  </p>
                  <div class="inline-flex">
                    <p class="font-bold text-gray-400 mt-3">
                      {{ giveARide ? 'ONLINE' : 'OFFLINE' }}
                    </p>
                    <va-toggle
                      class="m-2"
                      :size="size"
                      :value="giveARide"
                      @change="$emit('toggle-give-a-ride', $event)"
                    />
                  </div>
                </div>
              </RouterLink>
            </div>
            <div class="h-52p border bg-gray-200 border-gray-400 rounded">
              <div class="flex justify-between">
                <RouterLink :to="{ name: 'send-package' }">
                <div class="flex justify-between">
                  <p class="text-xs p-3 font-bold text-base mr-1 text-gray-750">
                    Send a package
                  </p>
                </div>
              </RouterLink>
                <Icon
                  name="package pl-16 text-base pr-5"
                  solid
                />
              </div>
            </div>
            <div class="h-52p border bg-gray-200 border-gray-400 rounded">
              <div class="flex justify-between">
                <p class="text-xs p-3 font-bold text-base text-gray-750">
                  Deliver a package
                </p>
                <div class="inline-flex">
                  <p class="font-bold text-gray-400 mt-3">
                    {{ deliverAPackage ? 'ONLINE' : 'OFFLINE' }}
                  </p>
                  <va-toggle
                    class="m-2"
                    :size="size"
                    :value="deliverAPackage"
                    @change="$emit('toggle-deliver-a-package', $event)"
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Ride',
  props: {
    size: {
      type: String,
      default: 'lg'
    },
    giveARide: {
      type: Boolean,
      default: false
    },
    deliverAPackage: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
