<template>
  <RouterLink class="shadow p-4 bg-white hover:bg-gray-100 rounded-lg block mt-2" :to="to" @click.native="$emit('click')">
      <div class="flex items-center flex-wrap justify-between">
        <span class="font-semibold text-sm">
          <slot></slot>
        </span>
        <FontAwesomeIcon size="lg" icon="angle-right"/>
      </div>
  </RouterLink>
</template>

<script>

export default {
  name: 'LinkArrowRight',
  props: {
    to: { default: '' }
  }
}
</script>
