<template>
  <div
    class="hover:bg-white w-full border-gray-hr border-b cursor-pointer"
    @click="$emit('click')"
  >
    <div class="px-4">
      <div class="flex my-4 justify-between">
        <div class="flex">
          <img
            src="../../public/img/profile.png"
            class="rounded-full w-6 h-6 m-2"
          >
          <div class="m-1">
            <p class="text-xs font-bold">
              {{ ride.name }}
            </p>
            <small class="text-10p font-semibold text-gray-400">{{ ride.vehicle }}</small>
            <div class="flex items-center">
              <Rating :rating="ride.rating" />
              <small class="text-10p mt-1 font-bold">{{ ride.votes }} votes</small>
            </div>
          </div>
        </div>
        <div class="flex">
          <div>
            <p class="font-bold text-xl mt-2">
              {{ ride.amount }} kr
            </p>
            <small class="text-10p font-bold text-primary-medium">80% MATCH </small>
          </div>
          <Icon
            class="font-bold text-base transform rotate-180 ml-3"
            name="bx bx-arrow-back"
            solid
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'RideCard',
  props: {
    ride: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
