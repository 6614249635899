<template>
  <div class="grid grid-cols-1 md:grid-cols-3">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'GridThreeColumn'
}
</script>
