<template>
  <div class="flex items-center">
    <va-icon :size="small ? '0.6rem' : '0.8rem'" color="#fff" :bg-color="color" :padding="small ? '4px' : '8px'" :type="icon" />
  </div>
</template>

<script>

export default {
  name: 'TypeIcon',
  props: {
    color: {
      default: ''
    },
    icon: {
      default: ''
    },
    label: {
      default: ''
    },
    small: {
      default: false
    }
  }
}
</script>
