<template>
  <div class="bg-yellow-500 relative p-4 rounded-lg my-4 text-black border border-yellow-600 flex items-center">
    <FontAwesomeIcon icon="times" v-if="canClose" @click="$emit('close')" class="text-md absolute top-0 right-0 mt-2 mr-2 text-shadow text-black cursor-pointer" />
    <FontAwesomeIcon icon="exclamation-triangle" class="text-2xl mr-4 text-shadow text-black" /> <div><slot></slot></div>
  </div>
</template>

<script>

export default {
  name: 'WarningMessage',
  props: {
    canClose: { default: false }
  }
}
</script>
