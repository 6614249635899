<template>
  <div class="grid grid-cols-4 lg:grid-cols-7">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'GridSevenColumn'
}
</script>
