<template>
  <CartDeposit />
</template>
<script>
import CartDeposit from './_components/CartDeposit'
export default {
  name: 'CartDepositModule',
  components: {
    CartDeposit
  }
}
</script>
