<template>
  <div
    class="modal-container dark:bg-custom-old-purple-500 dark:text-primary-dark mx-auto shadow-sm dark:shadow-sm-dark z-50 overflow-y-auto"
    :class="[height, position, extraClass, width, background]"
  >
    <slot />
  </div>
</template>

<script>

export default {
  name: 'ModalContainer',
  props: {
    height: {
      type: String,
      default: 'h-3/4'
    },
    width: {
      type: String,
      default: 'w-full md:max-w-md'
    },
    position: {
      type: String,
      default: 'absolute bottom-0'
    },
    background: {
      type: String,
      default: 'bg-white'
    },
    extraClass: {
      type: String,
      default: ''
    }
  }
}
</script>
